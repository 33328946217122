/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-bitwise */
/* eslint-disable eqeqeq */
import { store } from '@/common/core/store/decorator';
import { observable, action, computed } from 'mobx';
import NEWCAR_CONST from '@/newcar/util/const';
import CarDigest from '@/common/core/model/carDigest';
import VehicleGrade from '@/common/core/model/catalog/vehicleGrade';
import OptionFee from '@/common/core/model/catalog/optionFee';
import OptionInfo from '@/common/core/model/catalog/optionInfo';
import BodyColor from '@/common/core/model/catalog/bodyColor';
import BodyColorInfo from '@/common/core/model/catalog/bodyColorInfo';
import Option from '@/common/core/model/catalog/option';
import NEWCAR_DEFINE from '@/newcar/util/define';
import { WantCarBreakdown } from '@/newcar/core/store//dataset/entryBreakdown';
import { searchCarStore } from '@/newcar/core/store/searchCarStore';
import { entryBreakdownManagerStore } from '@/newcar/core/store/entryBreakdownManagerStore';
import StoreUtil from '@/common/util/util';
import CustomerSelection from '@/newcar/core/store/dataset/customerSelection';
import { SelectBodyColor, SelectOption } from '@/newcar/core/store/dataset/simulationResult';
import Log from '@/common/util/log';
import { IMaintenancePlan } from '@/newcar/core/store/dataset/maintenance';

export interface IGradeNameList {
  name: string;
  price: number;
  powerSlideDoor: NEWCAR_CONST.POWER_SLIDE_DOOR.ID;
  keylessEntry: NEWCAR_CONST.COMMON_OPTIONS.ID;
  smartKey: NEWCAR_CONST.COMMON_OPTIONS.ID;
  cruiseControl: NEWCAR_CONST.COMMON_OPTIONS.ID;
  automaticBrake: NEWCAR_CONST.COMMON_OPTIONS.ID;
  pedalSupport: NEWCAR_CONST.COMMON_OPTIONS.ID;
  laneDepartureAlert: NEWCAR_CONST.COMMON_OPTIONS.ID;
  defaultGrade: VehicleGrade;
  bodyColorList?: Array<object>;
}

// 指定グレードの車両本体価格(税込) // todo storeUtilに引っ越し
function calcVehiclePrice(grade: VehicleGrade, term: NEWCAR_CONST.TERM.ID): number {
  const SUPPORT_FEE = StoreUtil.calcTAX(100);
  if (term === NEWCAR_CONST.TERM.ID.YEAR_11) { return StoreUtil.calcTAX(grade.leasingFee132) + StoreUtil.calcTAX(grade.supportFee132) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee132); }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_10) { return StoreUtil.calcTAX(grade.leasingFee120) + StoreUtil.calcTAX(grade.supportFee120) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee120); }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_9) { return StoreUtil.calcTAX(grade.leasingFee108) + StoreUtil.calcTAX(grade.supportFee108) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee108); }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_8) { return StoreUtil.calcTAX(grade.leasingFee96) + StoreUtil.calcTAX(grade.supportFee96) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee96); }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_7) { return StoreUtil.calcTAX(grade.leasingFee84) + StoreUtil.calcTAX(grade.supportFee84) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee84); }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_6) { return StoreUtil.calcTAX(grade.leasingFee72) + StoreUtil.calcTAX(grade.supportFee72) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee72); }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_5) { return StoreUtil.calcTAX(grade.leasingFee60) + StoreUtil.calcTAX(grade.supportFee60) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee60); }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_4) { return StoreUtil.calcTAX(grade.leasingFee48) + StoreUtil.calcTAX(grade.supportFee48) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee48); }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_3) { return StoreUtil.calcTAX(grade.leasingFee36) + StoreUtil.calcTAX(grade.supportFee36) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee36); }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_2) { return StoreUtil.calcTAX(grade.leasingFee24) + StoreUtil.calcTAX(grade.supportFee24) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee24); }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_1) { return StoreUtil.calcTAX(grade.leasingFee12) + StoreUtil.calcTAX(grade.supportFee12) + SUPPORT_FEE + StoreUtil.calcTAX(grade.onlineDiscountFee12); }
  return 0;
}

// 指定グレードの車両本体価格(税込)、ネット割なし
function calcVehicleBasePrice(grade: VehicleGrade, term: NEWCAR_CONST.TERM.ID): number {
  const SUPPORT_FEE = StoreUtil.calcTAX(100);
  if (term === NEWCAR_CONST.TERM.ID.YEAR_11) { return StoreUtil.calcTAX(grade.leasingFee132) + StoreUtil.calcTAX(grade.supportFee132) + SUPPORT_FEE; }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_10) { return StoreUtil.calcTAX(grade.leasingFee120) + StoreUtil.calcTAX(grade.supportFee120) + SUPPORT_FEE; }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_9) { return StoreUtil.calcTAX(grade.leasingFee108) + StoreUtil.calcTAX(grade.supportFee108) + SUPPORT_FEE; }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_8) { return StoreUtil.calcTAX(grade.leasingFee96) + StoreUtil.calcTAX(grade.supportFee96) + SUPPORT_FEE; }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_7) { return StoreUtil.calcTAX(grade.leasingFee84) + StoreUtil.calcTAX(grade.supportFee84) + SUPPORT_FEE; }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_6) { return StoreUtil.calcTAX(grade.leasingFee72) + StoreUtil.calcTAX(grade.supportFee72) + SUPPORT_FEE; }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_5) { return StoreUtil.calcTAX(grade.leasingFee60) + StoreUtil.calcTAX(grade.supportFee60) + SUPPORT_FEE; }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_4) { return StoreUtil.calcTAX(grade.leasingFee48) + StoreUtil.calcTAX(grade.supportFee48) + SUPPORT_FEE; }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_3) { return StoreUtil.calcTAX(grade.leasingFee36) + StoreUtil.calcTAX(grade.supportFee36) + SUPPORT_FEE; }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_2) { return StoreUtil.calcTAX(grade.leasingFee24) + StoreUtil.calcTAX(grade.supportFee24) + SUPPORT_FEE; }
  if (term === NEWCAR_CONST.TERM.ID.YEAR_1) { return StoreUtil.calcTAX(grade.leasingFee12) + StoreUtil.calcTAX(grade.supportFee12) + SUPPORT_FEE; }
  return 0;
}

function listupGradeName(allGrades: VehicleGrade[], nowCar?: CustomerSelection): IGradeNameList[] {
  let grades = allGrades;

  // エンジンで絞り込み
  if (nowCar?.engine) {
    grades = grades.filter((grade) => (grade.engine || 'ガソリン') === NEWCAR_CONST.ENGINE.NAME[nowCar.engine!]);
  }

  // 駆動で絞り込み
  if (nowCar?.kudou) {
    grades = grades.filter((grade) => grade.kudou === NEWCAR_CONST.KUDOU.NAME[nowCar.kudou!]);
  }

  // ミッションで絞り込み
  if (nowCar?.mission) {
    grades = grades.filter((grade) => getMissionType(grade.mission) === nowCar.mission);
  }

  // 名称だけで重複除去
  const nameList: string[] = [];
  for (const targetGrade of grades) {
    let targetName = targetGrade.name;
    // アルトワークスのようにグレードが無い車種対策
    if (targetName === undefined) {
      targetName = '';
      targetGrade.name = '';
    }
    if (nameList.indexOf(targetName) < 0) {
      nameList.push(targetName);
    }
  }

  // 名前でのデフォルトの値段と各オプション状況を算出
  const result: IGradeNameList[] = [];
  for (const targetName of nameList) {
    // 同一名称で抽出
    const nameGrade: VehicleGrade[] = grades.filter((grade) => grade.name == targetName);
    // デフォルトを決めるために並び替え
    nameGrade.sort((a: VehicleGrade, b: VehicleGrade) => compareGradeNameInner(a, b));
    // 並び替え後の先頭１件がデフォルトのグレード
    const calcPrice = calcVehiclePrice(nameGrade[0], nowCar?.term || NEWCAR_CONST.TERM.ID.YEAR_11);
    const add: IGradeNameList = {
      name: targetName,
      price: calcPrice,
      powerSlideDoor: NEWCAR_CONST.POWER_SLIDE_DOOR.NAME_TO_ID(nameGrade[0].powerSlideDoor),
      keylessEntry: NEWCAR_CONST.COMMON_OPTIONS.NAME_TO_ID(nameGrade[0].keylessEntry),
      smartKey: NEWCAR_CONST.COMMON_OPTIONS.NAME_TO_ID(nameGrade[0].smartKey),
      cruiseControl: NEWCAR_CONST.COMMON_OPTIONS.NAME_TO_ID(nameGrade[0].cruiseControl),
      automaticBrake: NEWCAR_CONST.COMMON_OPTIONS.NAME_TO_ID(nameGrade[0].automaticBrake),
      pedalSupport: NEWCAR_CONST.COMMON_OPTIONS.NAME_TO_ID(nameGrade[0].pedalSupport),
      laneDepartureAlert: NEWCAR_CONST.COMMON_OPTIONS.NAME_TO_ID(nameGrade[0].laneDepartureAlert),
      defaultGrade: nameGrade[0],
      bodyColorList: selectedGradeBodyColorList(nameGrade[0], nowCar?.term || NEWCAR_CONST.TERM.ID.YEAR_11),
    };
    result.push(add);
  }

  // 価格の安い順に並び替え
  result.sort((a: IGradeNameList, b: IGradeNameList) => (a.price > b.price ? 1 : -1));
  return result;
}

function getDefaultGrade(allGrades: VehicleGrade[], nowCar?: CustomerSelection): VehicleGrade | null {
  const list = listupGradeName(allGrades, nowCar);
  if (list.length === 1) {
    return list[0].defaultGrade;
  } if (list.length > 1) {
    return list[1].defaultGrade;
  }
  return null;
}

function getRichGrade(allGrades: VehicleGrade[], nowCar?: CustomerSelection): VehicleGrade | null {
  const list = listupGradeName(allGrades, nowCar);
  if (list.length === 1) {
    return list[0].defaultGrade;
  } if (list.length > 1) {
    return list[list.length - 2].defaultGrade;
  }
  return null;
}

// 同一名称内でデフォルトを決めるための並び替え
function compareGradeNameInner(a: VehicleGrade, b: VehicleGrade): number {
  // 駆動 2WD > 4WD
  const is2WDA = (a.kudou && a.kudou === NEWCAR_CONST.KUDOU.NAME[NEWCAR_CONST.KUDOU.ID.IS_2WD]);
  const is2WDB = (b.kudou && b.kudou === NEWCAR_CONST.KUDOU.NAME[NEWCAR_CONST.KUDOU.ID.IS_2WD]);
  if (is2WDA != is2WDB) {
    // どちらかだけが2WDの場合、そちらを優先
    return (is2WDA ? -1 : 1);
  }
  // 定員 多い > 少ない
  const capacityA = (a.capacity) ? a.capacity : 0;
  const capacityB = (b.capacity) ? b.capacity : 0;
  if (a.capacity != b.capacity) {
    // 定員に差がある場合、定員が多いほうを優先
    return (capacityA > capacityB ? -1 : 1);
  }
  // ミッション オートマ系 > マニュアル系
  const isMTA = (a.mission && (a.mission === NEWCAR_CONST.MISSION.NAME[NEWCAR_CONST.MISSION.ID.IS_5FMT]
      || a.mission === NEWCAR_CONST.MISSION.NAME[NEWCAR_CONST.MISSION.ID.IS_6FMT]));
  const isMTB = (b.mission && (b.mission === NEWCAR_CONST.MISSION.NAME[NEWCAR_CONST.MISSION.ID.IS_5FMT]
      || b.mission === NEWCAR_CONST.MISSION.NAME[NEWCAR_CONST.MISSION.ID.IS_6FMT]));
  if (isMTA != isMTB) {
    // どちらかだけがMT車の場合、オートマ優先
    return (isMTA ? 1 : -1);
  }
  // 先進安全 通常装備 > 除去
  const hasSafetySystemA = (a.removedSafetySystem === undefined);
  const hasSafetySystemB = (b.removedSafetySystem === undefined);
  if (hasSafetySystemA != hasSafetySystemB) {
    // どちらかだけが先進安全装置除去の場合、除去していないほうを優先
    return (hasSafetySystemA ? -1 : 1);
  }

  return 0;
}

// 料金シミュレーションの初期値を作成する
function createNewCarCollection(id: string, type: NEWCAR_CONST.SIMULATION_PLAN.ID, grades: VehicleGrade[], nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE, isAvailableWantCar?: boolean, targetGrade?: VehicleGrade): CustomerSelection {
  const newCar: CustomerSelection = new CustomerSelection();

  newCar.id = id;
  newCar.masterNumber = searchCarStore.masterNumber;

  // 期間
  newCar.term = NEWCAR_CONST.TERM.ID.YEAR_11;

  // エンジン種別の保持状況
  const hasGas = (grades.find((grade) => (grade.engine == undefined)));
  const hasHybrid = (grades.find((grade) => (grade.engine === NEWCAR_CONST.ENGINE.NAME[NEWCAR_CONST.ENGINE.ID.IS_HYBRID])));
  const hasTurbo = (grades.find((grade) => (grade.engine === NEWCAR_CONST.ENGINE.NAME[NEWCAR_CONST.ENGINE.ID.IS_TURBO])));

  // グレード
  let defaultGrade: VehicleGrade | null = null;
  if (type == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
    // よくばりプランのグレード選定
    // ハイブリット>ターボ>ガソリンの優先度で絞り、上から2番目のグレード

    if (hasHybrid != undefined) {
      newCar.engine = NEWCAR_CONST.ENGINE.ID.IS_HYBRID;
    } else if (hasTurbo != undefined) {
      newCar.engine = NEWCAR_CONST.ENGINE.ID.IS_TURBO;
    } else if (hasGas != undefined) {
      newCar.engine = NEWCAR_CONST.ENGINE.ID.IS_GAS;
    } else {
      newCar.engine = NEWCAR_CONST.ENGINE.ID.IS_GAS;
    }

    defaultGrade = getRichGrade(grades, newCar);
  } else {
    // シンプル・おすすめ・オリジナルプラン、通常の料金シミュレーションのグレード選定
    // 2WD>4WDの優先度で絞り、AT>MTの優先度で絞り、下から2番目のグレード

    // 駆動
    const has2WD = grades.find((grade) => grade.kudou === '2WD');
    newCar.kudou = has2WD ? NEWCAR_CONST.KUDOU.ID.IS_2WD : NEWCAR_CONST.KUDOU.ID.IS_4WD;

    // ミッション
    const hasAT = grades.find((grade) => getMissionType(grade.mission) === 'AT');
    newCar.mission = hasAT ? 'AT' : 'MT';

    if (type == NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL) {
      // オリジナルプランのシミュレーション用初期値

      // ガソリン>ハイブリット>ターボ
      if (hasGas != undefined) {
        newCar.engine = NEWCAR_CONST.ENGINE.ID.IS_GAS;
      } else if (hasHybrid != undefined) {
        newCar.engine = NEWCAR_CONST.ENGINE.ID.IS_HYBRID;
      } else if (hasTurbo != undefined) {
        newCar.engine = NEWCAR_CONST.ENGINE.ID.IS_TURBO;
      } else {
        newCar.engine = NEWCAR_CONST.ENGINE.ID.IS_GAS;
      }

      // エンジンタイプで絞ってグレードが無い場合、駆動形式を変更
      if (!getDefaultGrade(grades, newCar)) {
        const has4WD = grades.find((grade) => grade.kudou === '4WD');
        if (has4WD) {
          newCar.kudou = NEWCAR_CONST.KUDOU.ID.IS_4WD;
        }
      }
      // エンジンタイプ・駆動形式で絞ってグレードが無い場合、ミッションを変更
      if (!getDefaultGrade(grades, newCar)) {
        const hasMT = grades.find((grade) => getMissionType(grade.mission) === 'MT');
        if (hasMT) {
          newCar.mission = 'MT';
        }
      }
    }

    defaultGrade = getDefaultGrade(grades, newCar);
  }
  if (targetGrade) {
    defaultGrade = targetGrade;
  }
  newCar.gradeId = defaultGrade?.id;
  newCar.gradeName = defaultGrade?.name;

  // ボディカラー、シート地の初期値なし
  newCar.bodyColorId = undefined;

  if (type == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
    // シンプルプランはオプション全てなし
    newCar.carNavi = '100001';
    newCar.etc = '100003';
    newCar.backCamera = '100002';
    newCar.floorMat = '100004';
    newCar.doorVisor = '100006';
    newCar.wantNo = '100005';
    newCar.wantCar = 'yes';
  } else if (type == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
    // よくばりプランはすべてのオプションがつく
    if (defaultGrade?.options && searchCarStore.allOptionInfoList) {
      for (const targetOption of defaultGrade.options) {
        const nowOptions = (searchCarStore.allOptionInfoList.find((option) => (option.id == targetOption.optionInfoId)));

        if (nowOptions && nowOptions.optionType === 'カーナビ') {
          newCar.carNavi = targetOption.id;
          continue;
        }
        if (nowOptions && nowOptions.optionType === 'ETC') {
          newCar.etc = targetOption.id;
          continue;
        }
        if (nowOptions && nowOptions.optionType === 'バックカメラ') {
          newCar.backCamera = targetOption.id;
          continue;
        }
        if (nowOptions && nowOptions.optionType === 'フロアマット') {
          newCar.floorMat = targetOption.id;
          continue;
        }
        if (nowOptions && nowOptions.optionType === 'ドアバイザー') {
          newCar.doorVisor = targetOption.id;
          continue;
        }
        if (nowOptions && nowOptions.optionType === '希望ナンバー') {
          newCar.wantNo = targetOption.id;
          continue;
        }
      }
    }
    if (isAvailableWantCar) {
      newCar.wantCar = 'yes';
    }
  } else {
    // おすすめ・オリジナルプラン・通常の料金シミュレーションはカーナビがデフォルトでつく、他はつかない
    if (defaultGrade && defaultGrade.options && searchCarStore.allOptionInfoList) {
      for (const targetOption of defaultGrade.options) {
        const nowOptions = (searchCarStore.allOptionInfoList.find((option) => (option.id == targetOption.optionInfoId)));
        if (nowOptions && nowOptions.optionType === 'カーナビ') {
          newCar.carNavi = targetOption.id;
          break;
        }
      }
    }
    newCar.etc = '100003';
    newCar.backCamera = '100002';
    newCar.floorMat = '100004';
    newCar.doorVisor = '100006';
    newCar.wantNo = '100005';
    newCar.wantCar = 'yes';
  }

  // メンテナンスプラン
  if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR || nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD) {
    if (type == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE || type == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP) {
      newCar.maintenancePlan = NEWCAR_CONST.MAINTENANCE.PLAN.NONE;
    } else if (type == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND || type == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      newCar.maintenancePlan = NEWCAR_CONST.MAINTENANCE.PLAN.GOLD;
    } else {
      newCar.maintenancePlan = NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED;
    }
  }

  // 標準装備がないか確認する
  const carnaviList = optionList(newCar.term, 'カーナビ', newCar, grades);
  if (newCar && carnaviList.length === 1 && carnaviList[0].isStandard === true) {
    newCar.carNavi = carnaviList[0].id;
  }
  const etcList = optionList(newCar.term, 'ETC', newCar, grades);
  if (newCar && etcList.length === 1 && etcList[0].isStandard === true) {
    newCar.etc = etcList[0].id;
  }
  const backCameraList = optionList(newCar.term, 'バックカメラ', newCar, grades);
  if (newCar && backCameraList.length === 1 && backCameraList[0].isStandard === true) {
    newCar.backCamera = backCameraList[0].id;
  }
  const doorViserlist = optionList(newCar.term, 'ドアバイザー', newCar, grades);
  if (newCar) {
    newCar.dontHaveDoorVisor = (doorViserlist.length === 0);
    if (doorViserlist.length === 1 && doorViserlist[0].isStandard === true) {
      newCar.doorVisor = doorViserlist[0].id;
    }
  }
  const floorMatList = optionList(newCar.term, 'フロアマット', newCar, grades);
  if (newCar && floorMatList.length === 1 && floorMatList[0].isStandard === true) {
    newCar.floorMat = floorMatList[0].id;
  }
  const watNoList = optionList(newCar.term, '希望ナンバー', newCar, grades);
  if (newCar && watNoList.length === 1 && watNoList[0].isStandard === true) {
    newCar.wantNo = watNoList[0].id;
  }

  return newCar;
}

function optionList(term: NEWCAR_CONST.TERM.ID, optionType: string, nowCar?: CustomerSelection, nowGrades?: VehicleGrade[]) {
  const result: Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> = [];
  if (nowCar && nowGrades) {
    const nowGrade = (nowGrades.find((grade) => (grade.id == nowCar.gradeId)));
    if (nowGrade && nowGrade.options && searchCarStore.allOptionInfoList && searchCarStore.allOptionFeeList) {
      for (let i = 0; i < nowGrade.options.length; i += 1) {
        const targetOoptions: Option = nowGrade.options[i];
        if (nowGrade.options) {
          const nowOptions = (searchCarStore.allOptionInfoList.find((option) => (option.id == targetOoptions.optionInfoId)));
          if (nowOptions && nowOptions.optionType === optionType) {
            const nowFees = (searchCarStore.allOptionFeeList.find((optionFee) => (optionFee.id == targetOoptions.optionFeeId)));
            let notId = '';
            let targetIsChecked = false;
            if (optionType === 'カーナビ') {
              notId = '100001';
              if (nowCar.carNavi && notId != nowCar.carNavi) { targetIsChecked = true; }
            } else if (optionType === 'バックカメラ') {
              notId = '100002';
              if (nowCar.backCamera && notId != nowCar.backCamera) { targetIsChecked = true; }
            } else if (optionType === 'ETC') {
              notId = '100003';
              if (nowCar.etc && notId != nowCar.etc) { targetIsChecked = true; }
            } else if (optionType === 'フロアマット') {
              notId = '100004';
              if (nowCar.floorMat && notId != nowCar.floorMat) { targetIsChecked = true; }
            } else if (optionType === '希望ナンバー') {
              notId = '100005';
              if (nowCar.wantNo && notId != nowCar.wantNo) { targetIsChecked = true; }
            } else if (optionType === 'ドアバイザー') {
              notId = '100006';
              if (nowCar.doorVisor && notId != nowCar.doorVisor) { targetIsChecked = true; }
            }
            if (targetOoptions.id && (targetOoptions.isStandard != undefined) && targetOoptions.optionInfoId && nowOptions && nowFees) {
              let price = 0;
              // 期間に合わせて変動
              if (term === NEWCAR_CONST.TERM.ID.YEAR_11) { price = StoreUtil.calcTAX(nowFees.leasingFee132) + StoreUtil.calcTAX(nowFees.supportFee132); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_10) { price = StoreUtil.calcTAX(nowFees.leasingFee120) + StoreUtil.calcTAX(nowFees.supportFee120); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_9) { price = StoreUtil.calcTAX(nowFees.leasingFee108) + StoreUtil.calcTAX(nowFees.supportFee108); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_8) { price = StoreUtil.calcTAX(nowFees.leasingFee96) + StoreUtil.calcTAX(nowFees.supportFee96); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_7) { price = StoreUtil.calcTAX(nowFees.leasingFee84) + StoreUtil.calcTAX(nowFees.supportFee84); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_6) { price = StoreUtil.calcTAX(nowFees.leasingFee72) + StoreUtil.calcTAX(nowFees.supportFee72); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_5) { price = StoreUtil.calcTAX(nowFees.leasingFee60) + StoreUtil.calcTAX(nowFees.supportFee60); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_4) { price = StoreUtil.calcTAX(nowFees.leasingFee48) + StoreUtil.calcTAX(nowFees.supportFee48); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_3) { price = StoreUtil.calcTAX(nowFees.leasingFee36) + StoreUtil.calcTAX(nowFees.supportFee36); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_2) { price = StoreUtil.calcTAX(nowFees.leasingFee24) + StoreUtil.calcTAX(nowFees.supportFee24); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_1) { price = StoreUtil.calcTAX(nowFees.leasingFee12) + StoreUtil.calcTAX(nowFees.supportFee12); }

              result.push({
                id: targetOoptions.id,
                notid: notId,
                isStandard: targetOoptions.isStandard,
                optionInfoId: targetOoptions.optionInfoId,
                thumbnailFileName: nowOptions.thumbnailFileName,
                name: nowOptions.name,
                description: nowOptions.description,
                optionType: nowOptions.optionType,
                price,
                isChecked: targetIsChecked,
              });
            }
          }
        }
      }
    }
  }
  return result;
}

// 指定したグレードのボディカラーリストを返却する
function selectedGradeBodyColorList(grade: VehicleGrade | undefined,
  checkedTerm?: NEWCAR_CONST.TERM.ID,
  checkedBodyColorId?: string): Array<{
    id: string, bodyColorInfoId: string,
    bodyColorName?: string,
    colorCode1?: string,
    colorCode2?: string,
    price?: number,
    leasingFee?: number,
    supportFee?: number,
    isChecked?: boolean }> {
  const result: Array<{
    id: string, bodyColorInfoId: string,
    bodyColorName?: string,
    colorCode1?: string,
    colorCode2?: string,
    price?: number,
    leasingFee?: number,
    supportFee?: number,
    isChecked?: boolean }> = [];
  if (grade && grade.bodyColors
            && searchCarStore.allBodyColorList
            && searchCarStore.allOptionFeeList) {
    for (let i = 0; i < grade.bodyColors.length; i += 1) {
      const targetBodyColor: BodyColor = grade.bodyColors[i];
      if (grade.bodyColors) {
        const nowColors = searchCarStore.allBodyColorList.find(
          (bodyColor) => (bodyColor.id == targetBodyColor.bodyColorInfoId),
        );
        const nowFees = searchCarStore.allOptionFeeList.find(
          (optionFee) => (optionFee.id == targetBodyColor.optionFeeId),
        );
        if (targetBodyColor.id && targetBodyColor.bodyColorInfoId && nowColors && nowFees) {
          let isChecked = false;
          if (checkedBodyColorId === targetBodyColor.id) {
            isChecked = true;
          }
          // 期間に合わせて変動
          let price = 0;
          let leasingFee = 0;
          let supportFee = 0;
          const term = (checkedTerm) || NEWCAR_CONST.TERM.ID.YEAR_11;
          if (term === NEWCAR_CONST.TERM.ID.YEAR_11) {
            price = StoreUtil.calcTAX(nowFees.leasingFee132)
             + StoreUtil.calcTAX(nowFees.supportFee132);
            leasingFee = (nowFees.leasingFee132) ? nowFees.leasingFee132 : 0;
            supportFee = (nowFees.supportFee132) ? nowFees.supportFee132 : 0;
          }
          if (term === NEWCAR_CONST.TERM.ID.YEAR_10) {
            price = StoreUtil.calcTAX(nowFees.leasingFee120)
            + StoreUtil.calcTAX(nowFees.supportFee120);
            leasingFee = (nowFees.leasingFee120) ? nowFees.leasingFee120 : 0;
            supportFee = (nowFees.supportFee120) ? nowFees.supportFee120 : 0;
          }
          if (term === NEWCAR_CONST.TERM.ID.YEAR_9) {
            price = StoreUtil.calcTAX(nowFees.leasingFee108)
             + StoreUtil.calcTAX(nowFees.supportFee108);
            leasingFee = (nowFees.leasingFee108) ? nowFees.leasingFee108 : 0;
            supportFee = (nowFees.supportFee108) ? nowFees.supportFee108 : 0;
          }
          if (term === NEWCAR_CONST.TERM.ID.YEAR_8) {
            price = StoreUtil.calcTAX(nowFees.leasingFee96)
             + StoreUtil.calcTAX(nowFees.supportFee96);
            leasingFee = (nowFees.leasingFee96) ? nowFees.leasingFee96 : 0;
            supportFee = (nowFees.supportFee96) ? nowFees.supportFee96 : 0;
          }
          if (term === NEWCAR_CONST.TERM.ID.YEAR_7) {
            price = StoreUtil.calcTAX(nowFees.leasingFee84)
            + StoreUtil.calcTAX(nowFees.supportFee84);
            leasingFee = (nowFees.leasingFee84) ? nowFees.leasingFee84 : 0;
            supportFee = (nowFees.supportFee84) ? nowFees.supportFee84 : 0;
          }
          if (term === NEWCAR_CONST.TERM.ID.YEAR_6) {
            price = StoreUtil.calcTAX(nowFees.leasingFee72)
             + StoreUtil.calcTAX(nowFees.supportFee72);
            leasingFee = (nowFees.leasingFee72) ? nowFees.leasingFee72 : 0;
            supportFee = (nowFees.supportFee72) ? nowFees.supportFee72 : 0;
          }
          if (term === NEWCAR_CONST.TERM.ID.YEAR_5) {
            price = StoreUtil.calcTAX(nowFees.leasingFee60)
            + StoreUtil.calcTAX(nowFees.supportFee60);
            leasingFee = (nowFees.leasingFee60) ? nowFees.leasingFee60 : 0;
            supportFee = (nowFees.supportFee60) ? nowFees.supportFee60 : 0;
          }
          if (term === NEWCAR_CONST.TERM.ID.YEAR_4) {
            price = StoreUtil.calcTAX(nowFees.leasingFee48)
             + StoreUtil.calcTAX(nowFees.supportFee48);
            leasingFee = (nowFees.leasingFee48) ? nowFees.leasingFee48 : 0;
            supportFee = (nowFees.supportFee48) ? nowFees.supportFee48 : 0;
          }
          if (term === NEWCAR_CONST.TERM.ID.YEAR_3) {
            price = StoreUtil.calcTAX(nowFees.leasingFee36)
              + StoreUtil.calcTAX(nowFees.supportFee36);
            leasingFee = (nowFees.leasingFee36) ? nowFees.leasingFee36 : 0;
            supportFee = (nowFees.supportFee36) ? nowFees.supportFee36 : 0;
          }
          if (term === NEWCAR_CONST.TERM.ID.YEAR_2) {
            price = StoreUtil.calcTAX(nowFees.leasingFee24)
            + StoreUtil.calcTAX(nowFees.supportFee24);
            leasingFee = (nowFees.leasingFee24) ? nowFees.leasingFee24 : 0;
            supportFee = (nowFees.supportFee24) ? nowFees.supportFee24 : 0;
          }
          if (term === NEWCAR_CONST.TERM.ID.YEAR_1) {
            price = StoreUtil.calcTAX(nowFees.leasingFee12)
            + StoreUtil.calcTAX(nowFees.supportFee12);
            leasingFee = (nowFees.leasingFee12) ? nowFees.leasingFee12 : 0;
            supportFee = (nowFees.supportFee12) ? nowFees.supportFee12 : 0;
          }

          result.push({
            id: targetBodyColor.id,
            bodyColorInfoId: targetBodyColor.bodyColorInfoId,
            bodyColorName: nowColors.bodyColorName,
            colorCode1: nowColors.colorCode1,
            colorCode2: nowColors.colorCode2,
            price,
            leasingFee,
            supportFee,
            isChecked,
          });
        }
      }
    }
  }

  function comparePop(
    a: { id: string,
      bodyColorInfoId: string,
       bodyColorName?: string,
        colorCode1?: string,
        colorCode2?: string,
        price?: number,
        isChecked?: boolean },
    b: { id: string,
      bodyColorInfoId: string,
      bodyColorName?: string,
      colorCode1?: string,
       colorCode2?: string,
       price?: number,
       isChecked?: boolean },
  ): number {
    if (a.price === b.price) {
      if (a.colorCode2 && !b.colorCode2) {
        // 単一色優先
        return 1;
      } if (!a.colorCode2 && b.colorCode2) {
        return -1;
      }
      // 両方単一、ないし二色の場合、彩度で。
      return (calcRgb(a.colorCode1) < calcRgb(b.colorCode1) ? 1 : -1);
    }
    const aPrice = (a.price === undefined) ? 0 : a.price;
    const bPrice = (b.price === undefined) ? 0 : b.price;
    return (aPrice > bPrice) ? 1 : -1;
  }

  // 並び替え
  result.sort((
    a: {
      id: string,
      bodyColorInfoId: string,
      bodyColorName?: string,
      colorCode1?: string,
      colorCode2?: string,
      price?: number,
      isChecked?: boolean },
    b: {
      id: string,
      bodyColorInfoId: string,
      bodyColorName?: string,
      colorCode1?: string,
      colorCode2?: string,
      price?: number,
      isChecked?: boolean },
  ) => comparePop(a, b));

  return result;
}

// カラーコードをrgbに変換して足す
function calcRgb(colorcode?: string) {
  let c = colorcode;
  if (c === undefined) { return 0; }
  // 先頭に#が含まれている場合は除外
  if (c.split('')[0] === '#') {
    c = c.substring(1);
  }
  return parseInt(c.substring(0, 2), 16)
   + parseInt(c.substring(2, 4), 16) + parseInt(c.substring(4, 6), 16);
}

// PC版料金シミュレーション用の選択情報を返却
function nowSelection(selection: CustomerSelection, grades: VehicleGrade[], nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE): {gradeName?: string, orderName?:string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} {
  const result: {
    gradeName?: string,
    orderName?:string,
    option?: string,
    color?:string,
    maintenance?: string,
    price?:number,
    term?:NEWCAR_CONST.TERM.ID,
    isNotApplicable?: boolean
  } = {};
  if (selection) {
    if (selection.gradeName) {
      result.gradeName = selection.gradeName;
    }
    if (selection.orderName) {
      result.orderName = selection.orderName;
    }
    if (selection.gradeName) {
      const regex = /\s\|/gi;
      const optionStr = selectedOptionStr(selection);
      if (optionStr) {
        result.option = optionStr.replace(regex, '、');
      }
    }
    if (selection.bodyColorId) {
      const bodyColor = selectedBodyColor(selection, grades);
      if (bodyColor && bodyColor.bodyColorName) {
        result.color = bodyColor.bodyColorName;
      }
    }
    if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.NOT_APPLICABLE) {
      result.maintenance = 'この車種では利用いただけません';
      result.isNotApplicable = true;
    } else {
      result.isNotApplicable = false;
      if (!selection.maintenancePlan) {
        result.maintenance = '未選択';
      } else if (selection.maintenancePlan == NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM || selection.maintenancePlan == NEWCAR_CONST.MAINTENANCE.PLAN.GOLD || selection.maintenancePlan == NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
        result.maintenance = NEWCAR_CONST.MAINTENANCE.PLAN_SHORT_NAME(selection.maintenancePlan);
      } else {
        result.maintenance = 'なし';
      }
    }
    if (selection.term) {
      result.term = selection.term;
    }

    result.price = nowSelectPrice(selection, grades, nowMaintenanceType);
  }
  return result;
}

// 現在の条件での金額算出
function nowSelectPrice(selection: CustomerSelection, grades: VehicleGrade[], nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE, isExcluteDiscount?:boolean): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = (grades.find((g) => (g.id == gradeId)));
    if (grade) {
      // 車両本体
      let sumPrice = 0;
      const term = (nowCar && nowCar.term) ? nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;
      const price = isExcluteDiscount ? calcVehicleBasePrice(grade, term) : calcVehiclePrice(grade, term);
      sumPrice = price;

      // ボディカラー
      if (nowCar.bodyColorId && grade.bodyColors && searchCarStore.allOptionFeeList) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = (grade.bodyColors.find((color) => (color.id == targetBodyColorId)));
        if (bodyColor) {
          const nowFees = (searchCarStore.allOptionFeeList.find((optionFee) => (optionFee.id == bodyColor.optionFeeId)));
          if (nowFees) {
            // 期間に合わせて変動
            if (term === NEWCAR_CONST.TERM.ID.YEAR_11) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee132) + StoreUtil.calcTAX(nowFees.supportFee132); }
            if (term === NEWCAR_CONST.TERM.ID.YEAR_10) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee120) + StoreUtil.calcTAX(nowFees.supportFee120); }
            if (term === NEWCAR_CONST.TERM.ID.YEAR_9) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee108) + StoreUtil.calcTAX(nowFees.supportFee108); }
            if (term === NEWCAR_CONST.TERM.ID.YEAR_8) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee96) + StoreUtil.calcTAX(nowFees.supportFee96); }
            if (term === NEWCAR_CONST.TERM.ID.YEAR_7) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee84) + StoreUtil.calcTAX(nowFees.supportFee84); }
            if (term === NEWCAR_CONST.TERM.ID.YEAR_6) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee72) + StoreUtil.calcTAX(nowFees.supportFee72); }
            if (term === NEWCAR_CONST.TERM.ID.YEAR_5) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee60) + StoreUtil.calcTAX(nowFees.supportFee60); }
            if (term === NEWCAR_CONST.TERM.ID.YEAR_4) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee48) + StoreUtil.calcTAX(nowFees.supportFee48); }
            if (term === NEWCAR_CONST.TERM.ID.YEAR_3) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee36) + StoreUtil.calcTAX(nowFees.supportFee36); }
            if (term === NEWCAR_CONST.TERM.ID.YEAR_2) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee24) + StoreUtil.calcTAX(nowFees.supportFee24); }
            if (term === NEWCAR_CONST.TERM.ID.YEAR_1) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee12) + StoreUtil.calcTAX(nowFees.supportFee12); }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') { checkOptions.push(nowCar.carNavi); }
      if (nowCar.backCamera && nowCar.backCamera != '100002') { checkOptions.push(nowCar.backCamera); }
      if (nowCar.etc && nowCar.etc != '100003') { checkOptions.push(nowCar.etc); }
      if (nowCar.floorMat && nowCar.floorMat != '100004') { checkOptions.push(nowCar.floorMat); }
      if (nowCar.wantNo && nowCar.wantNo != '100005') { checkOptions.push(nowCar.wantNo); }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') { checkOptions.push(nowCar.doorVisor); }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = (grade.options.find((option) => (option.id == targetCheckId)));
          // 標準は除外
          if (targetOption && targetOption.isStandard === false && searchCarStore.allOptionFeeList) {
            // 金額の特定
            const nowFees = (searchCarStore.allOptionFeeList.find((optionFee) => (optionFee.id == targetOption.optionFeeId)));
            if (nowFees) {
              // 期間に合わせて変動
              if (term === NEWCAR_CONST.TERM.ID.YEAR_11) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee132) + StoreUtil.calcTAX(nowFees.supportFee132); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_10) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee120) + StoreUtil.calcTAX(nowFees.supportFee120); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_9) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee108) + StoreUtil.calcTAX(nowFees.supportFee108); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_8) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee96) + StoreUtil.calcTAX(nowFees.supportFee96); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_7) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee84) + StoreUtil.calcTAX(nowFees.supportFee84); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_6) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee72) + StoreUtil.calcTAX(nowFees.supportFee72); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_5) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee60) + StoreUtil.calcTAX(nowFees.supportFee60); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_4) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee48) + StoreUtil.calcTAX(nowFees.supportFee48); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_3) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee36) + StoreUtil.calcTAX(nowFees.supportFee36); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_2) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee24) + StoreUtil.calcTAX(nowFees.supportFee24); }
              if (term === NEWCAR_CONST.TERM.ID.YEAR_1) { sumPrice += StoreUtil.calcTAX(nowFees.leasingFee12) + StoreUtil.calcTAX(nowFees.supportFee12); }
            }
          }
        }
      }
      // もらえるオプション
      if (nowCar.wantCar === 'yes') {
        sumPrice += getFreeTransferDiffLeasingFeeTaxedPrice(grade, term);
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term === NEWCAR_CONST.TERM.ID.YEAR_11 || term === NEWCAR_CONST.TERM.ID.YEAR_10 || term === NEWCAR_CONST.TERM.ID.YEAR_9) {
          // 108-132ヶ月軽自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice += 6980; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice += 4460; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice += 3340; }
        } else if (term === NEWCAR_CONST.TERM.ID.YEAR_8 || term === NEWCAR_CONST.TERM.ID.YEAR_7 || term === NEWCAR_CONST.TERM.ID.YEAR_6) {
          // 72-96ヶ月軽自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice += 6480; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice += 4460; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice += 3340; }
        } else {
          // 12-60ヶ月軽自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice += 5980; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice += 4970; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice += 3850; }
        }
      } else if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD) {
        if (term === NEWCAR_CONST.TERM.ID.YEAR_11 || term === NEWCAR_CONST.TERM.ID.YEAR_10 || term === NEWCAR_CONST.TERM.ID.YEAR_9) {
          // 108-132ヶ月普通自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice += 7480; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice += 4770; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice += 3850; }
        } else if (term === NEWCAR_CONST.TERM.ID.YEAR_8 || term === NEWCAR_CONST.TERM.ID.YEAR_7 || term === NEWCAR_CONST.TERM.ID.YEAR_6) {
          // 72-96ヶ月普通自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice += 6980; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice += 4770; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice += 3850; }
        } else {
          // 12-60ヶ月普通自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice += 6480; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice += 5280; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice += 4360; }
        }
      }
      return sumPrice;
    }
  }
  return undefined;
}

// 11年での金額算出
function term11SelectPrice(
  selection: CustomerSelection,
  grades: VehicleGrade[],
  nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE,
  isExcluteDiscount?: boolean,
): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = grades.find((g) => g.id == gradeId);
    if (grade) {
      // 車両本体
      let sumPrice11 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_11;
      const price = isExcluteDiscount
        ? calcVehicleBasePrice(grade, term)
        : calcVehiclePrice(grade, term);
      sumPrice11 = price;

      // ボディカラー
      if (
        nowCar.bodyColorId
        && grade.bodyColors
        && searchCarStore.allOptionFeeList
      ) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = grade.bodyColors.find(
          (color) => color.id == targetBodyColorId,
        );
        if (bodyColor) {
          const nowFees = searchCarStore.allOptionFeeList.find(
            (optionFee) => optionFee.id == bodyColor.optionFeeId,
          );
          if (nowFees) {
            if (term) {
              sumPrice11
                += StoreUtil.calcTAX(nowFees.leasingFee132)
                + StoreUtil.calcTAX(nowFees.supportFee132);
            }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') {
        checkOptions.push(nowCar.carNavi);
      }
      if (nowCar.backCamera && nowCar.backCamera != '100002') {
        checkOptions.push(nowCar.backCamera);
      }
      if (nowCar.etc && nowCar.etc != '100003') {
        checkOptions.push(nowCar.etc);
      }
      if (nowCar.floorMat && nowCar.floorMat != '100004') {
        checkOptions.push(nowCar.floorMat);
      }
      if (nowCar.wantNo && nowCar.wantNo != '100005') {
        checkOptions.push(nowCar.wantNo);
      }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') {
        checkOptions.push(nowCar.doorVisor);
      }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = grade.options.find((option) => option.id == targetCheckId);
          // 標準は除外
          if (
            targetOption
            && targetOption.isStandard === false
            && searchCarStore.allOptionFeeList
          ) {
            // 金額の特定
            const nowFees = searchCarStore.allOptionFeeList.find(
              (optionFee) => optionFee.id == targetOption.optionFeeId,
            );
            if (nowFees) {
              // 期間に合わせて変動
              if (term) {
                sumPrice11
                  += StoreUtil.calcTAX(nowFees.leasingFee132)
                  + StoreUtil.calcTAX(nowFees.supportFee132);
              }
            }
          }
        }
      }
      // もらえるオプション
      if (nowCar.wantCar === 'yes') {
        sumPrice11 += getFreeTransferDiffLeasingFeeTaxedPrice(grade, term);
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          // 108-132ヶ月軽自動車の場合
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice11 += 6980;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice11 += 4460;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice11 += 3340;
          }
        }
      } else if (
        nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD
      ) {
        if (term) {
          // 108-132ヶ月普通自動車の場合
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice11 += 7480;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice11 += 4770;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice11 += 3850;
          }
        }
      }
      return sumPrice11;
    }
  }
  return undefined;
}

// 10年での金額算出
function term10SelectPrice(
  selection: CustomerSelection,
  grades: VehicleGrade[],
  nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE,
  isExcluteDiscount?: boolean,
): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = grades.find((g) => g.id == gradeId);
    if (grade) {
      // 車両本体
      let sumPrice10 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_10;
      const price = isExcluteDiscount
        ? calcVehicleBasePrice(grade, term)
        : calcVehiclePrice(grade, term);
      sumPrice10 = price;

      // ボディカラー
      if (
        nowCar.bodyColorId
        && grade.bodyColors
        && searchCarStore.allOptionFeeList
      ) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = grade.bodyColors.find(
          (color) => color.id == targetBodyColorId,
        );
        if (bodyColor) {
          const nowFees = searchCarStore.allOptionFeeList.find(
            (optionFee) => optionFee.id == bodyColor.optionFeeId,
          );
          if (nowFees) {
            if (term) {
              sumPrice10
                += StoreUtil.calcTAX(nowFees.leasingFee120)
                + StoreUtil.calcTAX(nowFees.supportFee120);
            }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') {
        checkOptions.push(nowCar.carNavi);
      }
      if (nowCar.backCamera && nowCar.backCamera != '100002') {
        checkOptions.push(nowCar.backCamera);
      }
      if (nowCar.etc && nowCar.etc != '100003') {
        checkOptions.push(nowCar.etc);
      }
      if (nowCar.floorMat && nowCar.floorMat != '100004') {
        checkOptions.push(nowCar.floorMat);
      }
      if (nowCar.wantNo && nowCar.wantNo != '100005') {
        checkOptions.push(nowCar.wantNo);
      }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') {
        checkOptions.push(nowCar.doorVisor);
      }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = grade.options.find((option) => option.id == targetCheckId);
          // 標準は除外
          if (
            targetOption
            && targetOption.isStandard === false
            && searchCarStore.allOptionFeeList
          ) {
            // 金額の特定
            const nowFees = searchCarStore.allOptionFeeList.find(
              (optionFee) => optionFee.id == targetOption.optionFeeId,
            );
            if (nowFees) {
              // 期間に合わせて変動
              if (term) {
                sumPrice10
                  += StoreUtil.calcTAX(nowFees.leasingFee120)
                  + StoreUtil.calcTAX(nowFees.supportFee120);
              }
            }
          }
        }
      }
      // もらえるオプション
      if (nowCar.wantCar === 'yes') {
        sumPrice10 += getFreeTransferDiffLeasingFeeTaxedPrice(grade, term);
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          // 108-132ヶ月軽自動車の場合
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice10 += 6980;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice10 += 4460;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice10 += 3340;
          }
        }
      } else if (
        nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD
      ) {
        if (term) {
          // 108-132ヶ月普通自動車の場合
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice10 += 7480;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice10 += 4770;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice10 += 3850;
          }
        }
      }
      return sumPrice10;
    }
  }
  return undefined;
}

// 9年での金額算出
function term9SelectPrice(
  selection: CustomerSelection,
  grades: VehicleGrade[],
  nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE,
  isExcluteDiscount?: boolean,
): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = grades.find((g) => g.id == gradeId);
    if (grade) {
      // 車両本体
      let sumPrice9 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_9;
      const price = isExcluteDiscount
        ? calcVehicleBasePrice(grade, term)
        : calcVehiclePrice(grade, term);
      sumPrice9 = price;

      // ボディカラー
      if (
        nowCar.bodyColorId
        && grade.bodyColors
        && searchCarStore.allOptionFeeList
      ) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = grade.bodyColors.find(
          (color) => color.id == targetBodyColorId,
        );
        if (bodyColor) {
          const nowFees = searchCarStore.allOptionFeeList.find(
            (optionFee) => optionFee.id == bodyColor.optionFeeId,
          );
          if (nowFees) {
            if (term) {
              sumPrice9
                += StoreUtil.calcTAX(nowFees.leasingFee108)
                + StoreUtil.calcTAX(nowFees.supportFee108);
            }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') {
        checkOptions.push(nowCar.carNavi);
      }
      if (nowCar.backCamera && nowCar.backCamera != '100002') {
        checkOptions.push(nowCar.backCamera);
      }
      if (nowCar.etc && nowCar.etc != '100003') {
        checkOptions.push(nowCar.etc);
      }
      if (nowCar.floorMat && nowCar.floorMat != '100004') {
        checkOptions.push(nowCar.floorMat);
      }
      if (nowCar.wantNo && nowCar.wantNo != '100005') {
        checkOptions.push(nowCar.wantNo);
      }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') {
        checkOptions.push(nowCar.doorVisor);
      }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = grade.options.find((option) => option.id == targetCheckId);
          // 標準は除外
          if (
            targetOption
            && targetOption.isStandard === false
            && searchCarStore.allOptionFeeList
          ) {
            // 金額の特定
            const nowFees = searchCarStore.allOptionFeeList.find(
              (optionFee) => optionFee.id == targetOption.optionFeeId,
            );
            if (nowFees) {
              // 期間に合わせて変動
              if (term) {
                sumPrice9
                  += StoreUtil.calcTAX(nowFees.leasingFee108)
                  + StoreUtil.calcTAX(nowFees.supportFee108);
              }
            }
          }
        }
      }
      // もらえるオプション
      if (nowCar.wantCar === 'yes') {
        sumPrice9 += getFreeTransferDiffLeasingFeeTaxedPrice(grade, term);
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          // 108-132ヶ月軽自動車の場合
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice9 += 6980;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice9 += 4460;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice9 += 3340;
          }
        }
      } else if (
        nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD
      ) {
        if (term) {
          // 108-132ヶ月普通自動車の場合
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice9 += 7480;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice9 += 4770;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice9 += 3850;
          }
        }
      }
      return sumPrice9;
    }
  }
  return undefined;
}

// 8年の条件での金額算出
function term8SelectPrice(selection: CustomerSelection, grades: VehicleGrade[], nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE, isExcluteDiscount?:boolean): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = (grades.find((g) => (g.id == gradeId)));
    if (grade) {
      // 車両本体
      let sumPrice8 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_8;
      const price = isExcluteDiscount ? calcVehicleBasePrice(grade, term) : calcVehiclePrice(grade, term);
      sumPrice8 = price;

      // ボディカラー
      if (nowCar.bodyColorId && grade.bodyColors && searchCarStore.allOptionFeeList) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = (grade.bodyColors.find((color) => (color.id == targetBodyColorId)));
        if (bodyColor) {
          const nowFees = (searchCarStore.allOptionFeeList.find((optionFee) => (optionFee.id == bodyColor.optionFeeId)));
          if (nowFees) {
            if (term) { sumPrice8 += StoreUtil.calcTAX(nowFees.leasingFee96) + StoreUtil.calcTAX(nowFees.supportFee96); }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') { checkOptions.push(nowCar.carNavi); }
      if (nowCar.backCamera && nowCar.backCamera != '100002') { checkOptions.push(nowCar.backCamera); }
      if (nowCar.etc && nowCar.etc != '100003') { checkOptions.push(nowCar.etc); }
      if (nowCar.floorMat && nowCar.floorMat != '100004') { checkOptions.push(nowCar.floorMat); }
      if (nowCar.wantNo && nowCar.wantNo != '100005') { checkOptions.push(nowCar.wantNo); }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') { checkOptions.push(nowCar.doorVisor); }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = (grade.options.find((option) => (option.id == targetCheckId)));
          // 標準は除外
          if (targetOption && targetOption.isStandard === false && searchCarStore.allOptionFeeList) {
            // 金額の特定
            const nowFees = (searchCarStore.allOptionFeeList.find((optionFee) => (optionFee.id == targetOption.optionFeeId)));
            if (nowFees) {
              if (term) { sumPrice8 += StoreUtil.calcTAX(nowFees.leasingFee96) + StoreUtil.calcTAX(nowFees.supportFee96); }
            }
          }
        }
      }
      // もらえるオプション
      if (nowCar.wantCar === 'yes') {
        sumPrice8 += getFreeTransferDiffLeasingFeeTaxedPrice(grade, term);
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          // 72-96ヶ月軽自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice8 += 6480; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice8 += 4460; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice8 += 3340; }
        }
      } else if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD) {
        if (term) {
          // 72-96ヶ月普通自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice8 += 6980; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice8 += 4770; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice8 += 3850; }
        }
      }
      return sumPrice8;
    }
  }
  return undefined;
}

// 7年の条件での金額算出
function term7SelectPrice(selection: CustomerSelection, grades: VehicleGrade[], nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE, isExcluteDiscount?:boolean): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = (grades.find((g) => (g.id == gradeId)));
    if (grade) {
      // 車両本体
      let sumPrice7 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_7;
      const price = isExcluteDiscount ? calcVehicleBasePrice(grade, term) : calcVehiclePrice(grade, term);
      sumPrice7 = price;

      // ボディカラー
      if (nowCar.bodyColorId && grade.bodyColors && searchCarStore.allOptionFeeList) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = (grade.bodyColors.find((color) => (color.id == targetBodyColorId)));
        if (bodyColor) {
          const nowFees = (searchCarStore.allOptionFeeList.find((optionFee) => (optionFee.id == bodyColor.optionFeeId)));
          if (nowFees) {
            if (term) { sumPrice7 += StoreUtil.calcTAX(nowFees.leasingFee84) + StoreUtil.calcTAX(nowFees.supportFee84); }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') { checkOptions.push(nowCar.carNavi); }
      if (nowCar.backCamera && nowCar.backCamera != '100002') { checkOptions.push(nowCar.backCamera); }
      if (nowCar.etc && nowCar.etc != '100003') { checkOptions.push(nowCar.etc); }
      if (nowCar.floorMat && nowCar.floorMat != '100004') { checkOptions.push(nowCar.floorMat); }
      if (nowCar.wantNo && nowCar.wantNo != '100005') { checkOptions.push(nowCar.wantNo); }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') { checkOptions.push(nowCar.doorVisor); }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = (grade.options.find((option) => (option.id == targetCheckId)));
          // 標準は除外
          if (targetOption && targetOption.isStandard === false && searchCarStore.allOptionFeeList) {
            // 金額の特定
            const nowFees = (searchCarStore.allOptionFeeList.find((optionFee) => (optionFee.id == targetOption.optionFeeId)));
            if (nowFees) {
              if (term) { sumPrice7 += StoreUtil.calcTAX(nowFees.leasingFee84) + StoreUtil.calcTAX(nowFees.supportFee84); }
            }
          }
        }
      }
      // もらえるオプション
      if (nowCar.wantCar === 'yes') {
        sumPrice7 += getFreeTransferDiffLeasingFeeTaxedPrice(grade, term);
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          // 72-96ヶ月軽自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice7 += 6480; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice7 += 4460; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice7 += 3340; }
        }
      } else if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD) {
        if (term) {
          // 72-96ヶ月普通自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice7 += 6980; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice7 += 4770; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice7 += 3850; }
        }
      }
      return sumPrice7;
    }
  }
  return undefined;
}

// 6年の条件での金額算出
function term6SelectPrice(selection: CustomerSelection, grades: VehicleGrade[], nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE, isExcluteDiscount?:boolean): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = (grades.find((g) => (g.id == gradeId)));
    if (grade) {
      // 車両本体
      let sumPrice6 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_6;
      const price = isExcluteDiscount ? calcVehicleBasePrice(grade, term) : calcVehiclePrice(grade, term);
      sumPrice6 = price;

      // ボディカラー
      if (nowCar.bodyColorId && grade.bodyColors && searchCarStore.allOptionFeeList) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = (grade.bodyColors.find((color) => (color.id == targetBodyColorId)));
        if (bodyColor) {
          const nowFees = (searchCarStore.allOptionFeeList.find((optionFee) => (optionFee.id == bodyColor.optionFeeId)));
          if (nowFees) {
            if (term) { sumPrice6 += StoreUtil.calcTAX(nowFees.leasingFee72) + StoreUtil.calcTAX(nowFees.supportFee72); }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') { checkOptions.push(nowCar.carNavi); }
      if (nowCar.backCamera && nowCar.backCamera != '100002') { checkOptions.push(nowCar.backCamera); }
      if (nowCar.etc && nowCar.etc != '100003') { checkOptions.push(nowCar.etc); }
      if (nowCar.floorMat && nowCar.floorMat != '100004') { checkOptions.push(nowCar.floorMat); }
      if (nowCar.wantNo && nowCar.wantNo != '100005') { checkOptions.push(nowCar.wantNo); }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') { checkOptions.push(nowCar.doorVisor); }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = (grade.options.find((option) => (option.id == targetCheckId)));
          // 標準は除外
          if (targetOption && targetOption.isStandard === false && searchCarStore.allOptionFeeList) {
            // 金額の特定
            const nowFees = (searchCarStore.allOptionFeeList.find((optionFee) => (optionFee.id == targetOption.optionFeeId)));
            if (nowFees) {
              if (term) { sumPrice6 += StoreUtil.calcTAX(nowFees.leasingFee72) + StoreUtil.calcTAX(nowFees.supportFee72); }
            }
          }
        }
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          // 72-96ヶ月軽自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice6 += 6480; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice6 += 4460; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice6 += 3340; }
        }
      } else if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD) {
        if (term) {
          // 72-96ヶ月普通自動車の場合
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) { sumPrice6 += 6980; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) { sumPrice6 += 4770; }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) { sumPrice6 += 3850; }
        }
      }
      return sumPrice6;
    }
  }
  return undefined;
}

// 5年での金額算出
function term5SelectPrice(
  selection: CustomerSelection,
  grades: VehicleGrade[],
  nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE,
  isExcluteDiscount?: boolean,
): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = grades.find((g) => g.id == gradeId);
    if (grade) {
      // 車両本体
      let sumPrice5 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_5;
      const price = isExcluteDiscount
        ? calcVehicleBasePrice(grade, term)
        : calcVehiclePrice(grade, term);
      sumPrice5 = price;

      // ボディカラー
      if (
        nowCar.bodyColorId
        && grade.bodyColors
        && searchCarStore.allOptionFeeList
      ) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = grade.bodyColors.find(
          (color) => color.id == targetBodyColorId,
        );
        if (bodyColor) {
          const nowFees = searchCarStore.allOptionFeeList.find(
            (optionFee) => optionFee.id == bodyColor.optionFeeId,
          );
          if (nowFees) {
            if (term) {
              sumPrice5
                += StoreUtil.calcTAX(nowFees.leasingFee60) + StoreUtil.calcTAX(nowFees.supportFee60);
            }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') {
        checkOptions.push(nowCar.carNavi);
      }
      if (nowCar.backCamera && nowCar.backCamera != '100002') {
        checkOptions.push(nowCar.backCamera);
      }
      if (nowCar.etc && nowCar.etc != '100003') {
        checkOptions.push(nowCar.etc);
      }
      if (nowCar.floorMat && nowCar.floorMat != '100004') {
        checkOptions.push(nowCar.floorMat);
      }
      if (nowCar.wantNo && nowCar.wantNo != '100005') {
        checkOptions.push(nowCar.wantNo);
      }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') {
        checkOptions.push(nowCar.doorVisor);
      }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = grade.options.find((option) => option.id == targetCheckId);
          // 標準は除外
          if (
            targetOption
            && targetOption.isStandard === false
            && searchCarStore.allOptionFeeList
          ) {
            // 金額の特定
            const nowFees = searchCarStore.allOptionFeeList.find(
              (optionFee) => optionFee.id == targetOption.optionFeeId,
            );
            if (nowFees) {
              if (term) {
                sumPrice5
                  += StoreUtil.calcTAX(nowFees.leasingFee60) + StoreUtil.calcTAX(nowFees.supportFee60);
              }
            }
          }
        }
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice5 += 5980;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice5 += 4970;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice5 += 3850;
          }
        }
      } else if (
        nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD
      ) {
        if (term) {
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice5 += 6480;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice5 += 5280;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice5 += 4360;
          }
        }
      }
      return sumPrice5;
    }
  }
  return undefined;
}

// 4年での金額算出
function term4SelectPrice(
  selection: CustomerSelection,
  grades: VehicleGrade[],
  nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE,
  isExcluteDiscount?: boolean,
): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = grades.find((g) => g.id == gradeId);
    if (grade) {
      // 車両本体
      let sumPrice4 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_4;
      const price = isExcluteDiscount
        ? calcVehicleBasePrice(grade, term)
        : calcVehiclePrice(grade, term);
      sumPrice4 = price;

      // ボディカラー
      if (
        nowCar.bodyColorId
        && grade.bodyColors
        && searchCarStore.allOptionFeeList
      ) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = grade.bodyColors.find(
          (color) => color.id == targetBodyColorId,
        );
        if (bodyColor) {
          const nowFees = searchCarStore.allOptionFeeList.find(
            (optionFee) => optionFee.id == bodyColor.optionFeeId,
          );
          if (nowFees) {
            if (term) {
              sumPrice4
                += StoreUtil.calcTAX(nowFees.leasingFee48) + StoreUtil.calcTAX(nowFees.supportFee48);
            }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') {
        checkOptions.push(nowCar.carNavi);
      }
      if (nowCar.backCamera && nowCar.backCamera != '100002') {
        checkOptions.push(nowCar.backCamera);
      }
      if (nowCar.etc && nowCar.etc != '100003') {
        checkOptions.push(nowCar.etc);
      }
      if (nowCar.floorMat && nowCar.floorMat != '100004') {
        checkOptions.push(nowCar.floorMat);
      }
      if (nowCar.wantNo && nowCar.wantNo != '100005') {
        checkOptions.push(nowCar.wantNo);
      }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') {
        checkOptions.push(nowCar.doorVisor);
      }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = grade.options.find((option) => option.id == targetCheckId);
          // 標準は除外
          if (
            targetOption
            && targetOption.isStandard === false
            && searchCarStore.allOptionFeeList
          ) {
            // 金額の特定
            const nowFees = searchCarStore.allOptionFeeList.find(
              (optionFee) => optionFee.id == targetOption.optionFeeId,
            );
            if (nowFees) {
              if (term) {
                sumPrice4
                  += StoreUtil.calcTAX(nowFees.leasingFee48) + StoreUtil.calcTAX(nowFees.supportFee48);
              }
            }
          }
        }
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice4 += 5980;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice4 += 4970;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice4 += 3850;
          }
        }
      } else if (
        nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD
      ) {
        if (term) {
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice4 += 6480;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice4 += 5280;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice4 += 4360;
          }
        }
      }
      return sumPrice4;
    }
  }
  return undefined;
}

// 3年での金額算出
function term3SelectPrice(
  selection: CustomerSelection,
  grades: VehicleGrade[],
  nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE,
  isExcluteDiscount?: boolean,
): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = grades.find((g) => g.id == gradeId);
    if (grade) {
      // 車両本体
      let sumPrice3 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_3;
      const price = isExcluteDiscount
        ? calcVehicleBasePrice(grade, term)
        : calcVehiclePrice(grade, term);
      sumPrice3 = price;

      // ボディカラー
      if (
        nowCar.bodyColorId
        && grade.bodyColors
        && searchCarStore.allOptionFeeList
      ) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = grade.bodyColors.find(
          (color) => color.id == targetBodyColorId,
        );
        if (bodyColor) {
          const nowFees = searchCarStore.allOptionFeeList.find(
            (optionFee) => optionFee.id == bodyColor.optionFeeId,
          );
          if (nowFees) {
            if (term) {
              sumPrice3
                += StoreUtil.calcTAX(nowFees.leasingFee36) + StoreUtil.calcTAX(nowFees.supportFee36);
            }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') {
        checkOptions.push(nowCar.carNavi);
      }
      if (nowCar.backCamera && nowCar.backCamera != '100002') {
        checkOptions.push(nowCar.backCamera);
      }
      if (nowCar.etc && nowCar.etc != '100003') {
        checkOptions.push(nowCar.etc);
      }
      if (nowCar.floorMat && nowCar.floorMat != '100004') {
        checkOptions.push(nowCar.floorMat);
      }
      if (nowCar.wantNo && nowCar.wantNo != '100005') {
        checkOptions.push(nowCar.wantNo);
      }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') {
        checkOptions.push(nowCar.doorVisor);
      }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = grade.options.find((option) => option.id == targetCheckId);
          // 標準は除外
          if (
            targetOption
            && targetOption.isStandard === false
            && searchCarStore.allOptionFeeList
          ) {
            // 金額の特定
            const nowFees = searchCarStore.allOptionFeeList.find(
              (optionFee) => optionFee.id == targetOption.optionFeeId,
            );
            if (nowFees) {
              if (term) {
                sumPrice3
                  += StoreUtil.calcTAX(nowFees.leasingFee36) + StoreUtil.calcTAX(nowFees.supportFee36);
              }
            }
          }
        }
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice3 += 5980;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice3 += 4970;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice3 += 3850;
          }
        }
      } else if (
        nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD
      ) {
        if (term) {
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice3 += 6480;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice3 += 5280;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice3 += 4360;
          }
        }
      }
      return sumPrice3;
    }
  }
  return undefined;
}

// 2年での金額算出
function term2SelectPrice(
  selection: CustomerSelection,
  grades: VehicleGrade[],
  nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE,
  isExcluteDiscount?: boolean,
): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = grades.find((g) => g.id == gradeId);
    if (grade) {
      // 車両本体
      let sumPrice2 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_2;
      const price = isExcluteDiscount
        ? calcVehicleBasePrice(grade, term)
        : calcVehiclePrice(grade, term);
      sumPrice2 = price;

      // ボディカラー
      if (
        nowCar.bodyColorId
        && grade.bodyColors
        && searchCarStore.allOptionFeeList
      ) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = grade.bodyColors.find(
          (color) => color.id == targetBodyColorId,
        );
        if (bodyColor) {
          const nowFees = searchCarStore.allOptionFeeList.find(
            (optionFee) => optionFee.id == bodyColor.optionFeeId,
          );
          if (nowFees) {
            if (term) {
              sumPrice2
                += StoreUtil.calcTAX(nowFees.leasingFee24) + StoreUtil.calcTAX(nowFees.supportFee24);
            }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') {
        checkOptions.push(nowCar.carNavi);
      }
      if (nowCar.backCamera && nowCar.backCamera != '100002') {
        checkOptions.push(nowCar.backCamera);
      }
      if (nowCar.etc && nowCar.etc != '100003') {
        checkOptions.push(nowCar.etc);
      }
      if (nowCar.floorMat && nowCar.floorMat != '100004') {
        checkOptions.push(nowCar.floorMat);
      }
      if (nowCar.wantNo && nowCar.wantNo != '100005') {
        checkOptions.push(nowCar.wantNo);
      }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') {
        checkOptions.push(nowCar.doorVisor);
      }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = grade.options.find((option) => option.id == targetCheckId);
          // 標準は除外
          if (
            targetOption
            && targetOption.isStandard === false
            && searchCarStore.allOptionFeeList
          ) {
            // 金額の特定
            const nowFees = searchCarStore.allOptionFeeList.find(
              (optionFee) => optionFee.id == targetOption.optionFeeId,
            );
            if (nowFees) {
              if (term) {
                sumPrice2
                  += StoreUtil.calcTAX(nowFees.leasingFee24) + StoreUtil.calcTAX(nowFees.supportFee24);
              }
            }
          }
        }
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice2 += 5980;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice2 += 4970;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice2 += 3850;
          }
        }
      } else if (
        nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD
      ) {
        if (term) {
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice2 += 6480;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice2 += 5280;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice2 += 4360;
          }
        }
      }
      return sumPrice2;
    }
  }
  return undefined;
}

// 1年での金額算出
function term1SelectPrice(
  selection: CustomerSelection,
  grades: VehicleGrade[],
  nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE,
  isExcluteDiscount?: boolean,
): number | undefined {
  const nowCar = selection;
  if (nowCar && nowCar.gradeId) {
    // グレードの特定
    const { gradeId } = nowCar;
    const grade = grades.find((g) => g.id == gradeId);
    if (grade) {
      // 車両本体
      let sumPrice1 = 0;
      const term = NEWCAR_CONST.TERM.ID.YEAR_1;
      const price = isExcluteDiscount
        ? calcVehicleBasePrice(grade, term)
        : calcVehiclePrice(grade, term);
      sumPrice1 = price;

      // ボディカラー
      if (
        nowCar.bodyColorId
        && grade.bodyColors
        && searchCarStore.allOptionFeeList
      ) {
        const targetBodyColorId = nowCar.bodyColorId;
        const bodyColor = grade.bodyColors.find(
          (color) => color.id == targetBodyColorId,
        );
        if (bodyColor) {
          const nowFees = searchCarStore.allOptionFeeList.find(
            (optionFee) => optionFee.id == bodyColor.optionFeeId,
          );
          if (nowFees) {
            if (term) {
              sumPrice1
                += StoreUtil.calcTAX(nowFees.leasingFee12) + StoreUtil.calcTAX(nowFees.supportFee12);
            }
          }
        }
      }

      // オプション
      const checkOptions: string[] = [];
      if (nowCar.carNavi && nowCar.carNavi != '100001') {
        checkOptions.push(nowCar.carNavi);
      }
      if (nowCar.backCamera && nowCar.backCamera != '100002') {
        checkOptions.push(nowCar.backCamera);
      }
      if (nowCar.etc && nowCar.etc != '100003') {
        checkOptions.push(nowCar.etc);
      }
      if (nowCar.floorMat && nowCar.floorMat != '100004') {
        checkOptions.push(nowCar.floorMat);
      }
      if (nowCar.wantNo && nowCar.wantNo != '100005') {
        checkOptions.push(nowCar.wantNo);
      }
      if (nowCar.doorVisor && nowCar.doorVisor != '100006') {
        checkOptions.push(nowCar.doorVisor);
      }
      for (const targetCheckId of checkOptions) {
        if (grade.options) {
          // オプションの特定
          const targetOption = grade.options.find((option) => option.id == targetCheckId);
          // 標準は除外
          if (
            targetOption
            && targetOption.isStandard === false
            && searchCarStore.allOptionFeeList
          ) {
            // 金額の特定
            const nowFees = searchCarStore.allOptionFeeList.find(
              (optionFee) => optionFee.id == targetOption.optionFeeId,
            );
            if (nowFees) {
              if (term) {
                sumPrice1
                  += StoreUtil.calcTAX(nowFees.leasingFee12) + StoreUtil.calcTAX(nowFees.supportFee12);
              }
            }
          }
        }
      }

      // メンテプラン
      if (nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.MINICAR) {
        if (term) {
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice1 += 5980;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice1 += 4970;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice1 += 3850;
          }
        }
      } else if (
        nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD
      ) {
        if (term) {
          if (
            nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM
          ) {
            sumPrice1 += 6480;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
            sumPrice1 += 5280;
          }
          if (nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
            sumPrice1 += 4360;
          }
        }
      }
      return sumPrice1;
    }
  }
  return undefined;
}

// 選択したオプションを文字列にする
function selectedOptionStr(selection: CustomerSelection): string | undefined {
  let result: string | undefined = '';
  if (selection != undefined) {
    if (selection.carNavi != undefined && selection.carNavi != '100001') {
      result += 'カーナビ |';
    }
    if (selection.backCamera != undefined && selection.backCamera != '100002') {
      result += 'バックカメラ |';
    }
    if (selection.etc != undefined && selection.etc != '100003') {
      result += 'ETC |';
    }
    if (selection.floorMat != undefined && selection.floorMat != '100004') {
      result += 'フロアマット |';
    }
    if (selection.wantNo != undefined && selection.wantNo != '100005') {
      result += '希望ナンバー |';
    }
    if (selection.doorVisor != undefined && selection.doorVisor != '100006') {
      result += 'ドアバイザー |';
    }
    const term = (selection && selection.term) ? selection.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    if (selection.wantCar == 'yes' && (term == NEWCAR_CONST.TERM.ID.YEAR_11 || term == NEWCAR_CONST.TERM.ID.YEAR_10 || term == NEWCAR_CONST.TERM.ID.YEAR_9 || term == NEWCAR_CONST.TERM.ID.YEAR_8 || term == NEWCAR_CONST.TERM.ID.YEAR_7)) {
      result += 'もらえるオプション |';
    }
    if (result.length > 2) {
      result = result.substr(0, result.length - 2);
    }
    if (selection.carNavi === undefined && selection.backCamera === undefined && selection.etc === undefined
          && selection.floorMat === undefined && selection.wantNo === undefined && selection.doorVisor === undefined
          && selection.wantCar === undefined) {
      result = undefined;
    }
  }
  if (!result) {
    result = 'なし';
  }
  return result;
}

function selectedBodyColor(selection: CustomerSelection, grades: VehicleGrade[]): { bodyColorId?: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string } | undefined {
  if (selection != undefined && selection.bodyColorId != undefined && searchCarStore.allBodyColorList != undefined) {
    // グレードの特定
    const targetGradeId = selection.gradeId;
    const targetGrade = (grades.find((grade) => (grade.id == targetGradeId)));
    if (targetGrade && targetGrade.bodyColors) {
      // ボディカラーの特定
      const targetBodyColor = (targetGrade.bodyColors.find((bodyColr) => (bodyColr.id == selection.bodyColorId)));
      if (targetBodyColor) {
        // ボディカラーInfoIdの特定
        const targetBodyColorInfo = (searchCarStore.allBodyColorList.find((bodyColr) => (bodyColr.id == targetBodyColor.bodyColorInfoId)));
        if (targetBodyColorInfo) {
          return {
            bodyColorId: targetBodyColor.id,
            bodyColorName: targetBodyColorInfo.bodyColorName,
            colorCode1: targetBodyColorInfo.colorCode1,
            colorCode2: targetBodyColorInfo.colorCode2,
          };
        }
      }
    }
  }
  return undefined;
}

// もらえるオプションの金額取得
function getFreeTransferDiffLeasingFeeTaxedPrice(grade: VehicleGrade | undefined, term: NEWCAR_CONST.TERM.ID | undefined): number {
  if (!grade || !term) {
    return 0;
  }
  // もらえるプランは7年以上から
  if (term < NEWCAR_CONST.TERM.ID.YEAR_7) {
    return 0;
  }
  const month = term * 12;
  return grade[`freeTransferDiffLeasingFeeTaxed${month}` as keyof VehicleGrade] as number || 0;
}

// AT/MTの判定
function getMissionType(mission: string | undefined): CustomerSelection['mission'] {
  return mission && mission.includes('MT') ? 'MT' : 'AT';
}

@store()
export default class SimulationManagerStore {
  // 車種別のシミュレーション選択結果を格納
  @observable userSelect: CustomerSelection[] = [];

  @observable lastEntryId?: string = '';

  // 現在シミレーション中の車種
  @observable nowCar?: CustomerSelection = undefined;

  @observable wantCar?: string = '';

  // 現在選択された車種に存在するグレードの詳細
  @observable nowGrades: VehicleGrade[] = [];

  // 現在選択された車種で適用されるメンテプランのタイプ
  @observable nowMaintenanceType: NEWCAR_CONST.MAINTENANCE.TYPE = NEWCAR_CONST.MAINTENANCE.TYPE.NOT_APPLICABLE;

  // ボディカラー、シート地、オプション、メンテナスプランのリセットフラグ
  @observable isResetBodyColor = false;

  @observable isResetOption = false;

  @observable isResetMaintenance = false;

  // PC版料金シミュレーション用 各プラン
  @observable simplePlanSelect?: CustomerSelection = undefined;

  @observable recommendPlanSelect?: CustomerSelection = undefined;

  @observable richPlanSelect?: CustomerSelection = undefined;

  // SP版料金シミュレーション オススメ
  @observable recommendSPPlanSelect?: CustomerSelection = undefined;

  @observable isShowSimulateAlert = false;

  @action
  setIsShowSimulateAlert(isShow: boolean) {
    this.isShowSimulateAlert = isShow;
  }

  @computed
  get nowSelectId(): string {
    if (this.nowCar) {
      return this.nowCar.id;
    }
    return '';
  }

  @computed
  get nowSelectTerm(): NEWCAR_CONST.TERM.ID {
    Log.trace('nowSelectTerm()');
    if (this.nowCar) {
      Log.trace('this.nowCar is exists');
      if (this.nowCar.term) {
        Log.trace(`this.nowCar.term = ${this.nowCar.term}`);
      } else {
        Log.trace('this.nowCar.term is none');
      }
    } else {
      Log.trace('this.nowCar is none');
    }

    return (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    //        return term;
  }

  // PC版料金シミュレーション用 シンプルプラン情報返却
  @computed
  get nowSimpleSelection(): {gradeName?: string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} {
    let result:{gradeName?: string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} = {};
    if (this.simplePlanSelect) {
      result = nowSelection(this.simplePlanSelect, this.nowGrades, this.nowMaintenanceType);
    }
    return result;
  }

  // PC版料金シミュレーション用 おすすめプラン情報返却
  @computed
  get nowRecommendSelection(): {gradeName?: string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} {
    let result:{gradeName?: string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} = {};
    if (this.recommendPlanSelect) {
      result = nowSelection(this.recommendPlanSelect, this.nowGrades, this.nowMaintenanceType);
    }
    return result;
  }

  // PC版料金シミュレーション用 よくばりプラン情報返却
  @computed
  get nowRichSelection(): {gradeName?: string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} {
    let result:{gradeName?: string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} = {};
    if (this.richPlanSelect) {
      result = nowSelection(this.richPlanSelect, this.nowGrades, this.nowMaintenanceType);
    }
    return result;
  }

  // PC版料金シミュレーション用 オリジナルプラン情報返却
  @computed
  get nowOriginalSelection(): {gradeName?: string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} {
    let result:{gradeName?: string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} = {};
    if (this.nowCar) {
      result = nowSelection(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return result;
  }

  // SP版料金シミュレーション用 オススメプラン情報返却
  @computed
  get nowRecommendSPSelection(): {gradeName?: string, orderName?:string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} {
    let result:{gradeName?: string, orderName?:string, option?: string, color?:string, maintenance?: string, price?:number, term?:NEWCAR_CONST.TERM.ID, isNotApplicable?: boolean} = {};
    if (this.recommendSPPlanSelect) {
      result = nowSelection(this.recommendSPPlanSelect, this.nowGrades, this.nowMaintenanceType);
    }
    return result;
  }

  // SP版料金シミュレーション用 オススメプラン情報返却
  @computed
  get nowRecommendPlanSP(): CustomerSelection | undefined {
    return this.recommendSPPlanSelect;
  }

  @computed
  get nowSelectGrade(): { name: string, sub: string } | undefined {
    if (!this.nowCar || !this.nowCar.gradeId) {
      return undefined;
    }

    const nowGrade = this.nowGrades.find((grade) => grade.id == this.nowCar!.gradeId);
    const subList = [];
    subList.push(nowGrade?.engine || 'ガソリン');
    if (nowGrade?.kudou) {
      subList.push(nowGrade.kudou);
    }
    if (nowGrade?.capacity) {
      subList.push(NEWCAR_CONST.CAPACITY.NAME[nowGrade.capacity]);
    }
    return {
      name: nowGrade?.name || '',
      sub: subList.join(' | '),
    };
  }

  @computed
  get nowSelectGradeId(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return this.nowCar.gradeId;
    }
    return undefined;
  }

  @computed
  get nowSelectBodyColor(): { bodyColorId?: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string } | undefined {
    const { nowCar } = this;
    if (nowCar) {
      return selectedBodyColor(nowCar, this.nowGrades);
    }
    return undefined;
  }

  // PC版料金シミュレーション用 シンプルプランの選択中ボディカラー
  @computed
  get nowSelectSimpleBodyColor(): { bodyColorId?: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string } | undefined {
    const nowCar = this.simplePlanSelect;
    if (nowCar) {
      return selectedBodyColor(nowCar, this.nowGrades);
    }
    return undefined;
  }

  // PC版料金シミュレーション用 おすすめプランの選択中ボディカラー
  @computed
  get nowSelectRecommendBodyColor(): { bodyColorId?: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string } | undefined {
    const nowCar = this.recommendPlanSelect;
    if (nowCar) {
      return selectedBodyColor(nowCar, this.nowGrades);
    }
    return undefined;
  }

  // PC版料金シミュレーション用 よくばりプランの選択中ボディカラー
  @computed
  get nowSelectRichBodyColor(): { bodyColorId?: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string } | undefined {
    const nowCar = this.richPlanSelect;
    if (nowCar) {
      return selectedBodyColor(nowCar, this.nowGrades);
    }
    return undefined;
  }

  @computed
  get nowSelectOption(): string | undefined {
    let result: string | undefined = '';
    if (this.nowCar != undefined) {
      if (this.nowCar.carNavi != undefined && this.nowCar.carNavi != '100001') {
        result += 'カーナビ |';
      }
      if (this.nowCar.backCamera != undefined && this.nowCar.backCamera != '100002') {
        result += 'バックカメラ |';
      }
      if (this.nowCar.etc != undefined && this.nowCar.etc != '100003') {
        result += 'ETC |';
      }
      if (this.nowCar.floorMat != undefined && this.nowCar.floorMat != '100004') {
        result += 'フロアマット |';
      }
      if (this.nowCar.wantNo != undefined && this.nowCar.wantNo != '100005') {
        result += '希望ナンバー |';
      }
      if (this.nowCar.doorVisor != undefined && this.nowCar.doorVisor != '100006') {
        result += 'ドアバイザー |';
      }
      const term = (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;
      if (this.nowCar.wantCar === 'yes' && (term == NEWCAR_CONST.TERM.ID.YEAR_11 || term == NEWCAR_CONST.TERM.ID.YEAR_10 || term == NEWCAR_CONST.TERM.ID.YEAR_9 || term == NEWCAR_CONST.TERM.ID.YEAR_8 || term == NEWCAR_CONST.TERM.ID.YEAR_7)) {
        result += 'もらえるオプション |';
      }
      if (result.length > 2) {
        result = result.substr(0, result.length - 2);
      }
      if (this.nowCar.carNavi === undefined && this.nowCar.backCamera === undefined && this.nowCar.etc === undefined
            && this.nowCar.floorMat === undefined && this.nowCar.wantNo === undefined && this.nowCar.doorVisor === undefined
            && this.nowCar.wantCar === undefined) {
        result = undefined;
      }
    }
    return result;
  }

  @computed
  get nowSelectCarNavi(): string {
    let result = '100001';
    if (this.nowCar != undefined && this.nowCar.carNavi != undefined) {
      result = this.nowCar.carNavi;
    }
    return result;
  }

  // PC版料金シミュレーション用 シンプルプランの選択中 カーナビ
  @computed
  get nowSelectSimpleCarNavi(): string {
    let result = '100001';
    if (this.simplePlanSelect != undefined && this.simplePlanSelect.carNavi != undefined) {
      result = this.simplePlanSelect.carNavi;
    }
    return result;
  }

  // PC版料金シミュレーション用 おすすめプランの選択中 カーナビ
  @computed
  get nowSelectRecommendCarNavi(): string {
    let result = '100001';
    if (this.recommendPlanSelect != undefined && this.recommendPlanSelect.carNavi != undefined) {
      result = this.recommendPlanSelect.carNavi;
    }
    return result;
  }

  // PC版料金シミュレーション用 よくばりプランの選択中 カーナビ
  @computed
  get nowSelectRichCarNavi(): string {
    let result = '100001';
    if (this.richPlanSelect != undefined && this.richPlanSelect.carNavi != undefined) {
      result = this.richPlanSelect.carNavi;
    }
    return result;
  }

  @computed
  get nowSelectEtc(): string {
    let result = '100003';
    if (this.nowCar != undefined && this.nowCar.etc != undefined) {
      result = this.nowCar.etc;
    }
    return result;
  }

  // PC版料金シミュレーション用 シンプルプランの選択中 ETC
  @computed
  get nowSelectSimpleEtc(): string {
    let result = '100003';
    if (this.simplePlanSelect != undefined && this.simplePlanSelect.etc != undefined) {
      result = this.simplePlanSelect.etc;
    }
    return result;
  }

  // PC版料金シミュレーション用 おすすめプランの選択中 ETC
  @computed
  get nowSelectRecommendEtc(): string {
    let result = '100003';
    if (this.recommendPlanSelect != undefined && this.recommendPlanSelect.etc != undefined) {
      result = this.recommendPlanSelect.etc;
    }
    return result;
  }

  // PC版料金シミュレーション用 よくばりプランの選択中 ETC
  @computed
  get nowSelectRichEtc(): string {
    let result = '100003';
    if (this.richPlanSelect != undefined && this.richPlanSelect.etc != undefined) {
      result = this.richPlanSelect.etc;
    }
    return result;
  }

  @computed
  get nowSelectBackCamera(): string {
    let result = '100002';
    if (this.nowCar != undefined && this.nowCar.backCamera != undefined) {
      result = this.nowCar.backCamera;
    }
    return result;
  }

  // PC版料金シミュレーション用 シンプルプランの選択中 バックカメラ
  @computed
  get nowSelectSimpleBackCamera(): string {
    let result = '100002';
    if (this.simplePlanSelect != undefined && this.simplePlanSelect.backCamera != undefined) {
      result = this.simplePlanSelect.backCamera;
    }
    return result;
  }

  // PC版料金シミュレーション用 おすすめプランの選択中 バックカメラ
  @computed
  get nowSelectRecommendBackCamera(): string {
    let result = '100002';
    if (this.recommendPlanSelect != undefined && this.recommendPlanSelect.backCamera != undefined) {
      result = this.recommendPlanSelect.backCamera;
    }
    return result;
  }

  // PC版料金シミュレーション用 よくばりプランの選択中 バックカメラ
  @computed
  get nowSelectRichBackCamera(): string {
    let result = '100002';
    if (this.richPlanSelect != undefined && this.richPlanSelect.backCamera != undefined) {
      result = this.richPlanSelect.backCamera;
    }
    return result;
  }

  @computed
  get nowSelectFloorMat(): string {
    let result = '100004';
    if (this.nowCar != undefined && this.nowCar.floorMat != undefined) {
      result = this.nowCar.floorMat;
    }
    return result;
  }

  // PC版料金シミュレーション用 シンプルプランの選択中 フロアマット
  @computed
  get nowSelectSimpleFloorMat(): string {
    let result = '100004';
    if (this.simplePlanSelect != undefined && this.simplePlanSelect.floorMat != undefined) {
      result = this.simplePlanSelect.floorMat;
    }
    return result;
  }

  // PC版料金シミュレーション用 おすすめプランの選択中 フロアマット
  @computed
  get nowSelectRecommendFloorMat(): string {
    let result = '100004';
    if (this.recommendPlanSelect != undefined && this.recommendPlanSelect.floorMat != undefined) {
      result = this.recommendPlanSelect.floorMat;
    }
    return result;
  }

  // PC版料金シミュレーション用 よくばりプランの選択中 フロアマット
  @computed
  get nowSelectRichFloorMat(): string {
    let result = '100004';
    if (this.richPlanSelect != undefined && this.richPlanSelect.floorMat != undefined) {
      result = this.richPlanSelect.floorMat;
    }
    return result;
  }

  @computed
  get nowSelectDoorVisor(): string {
    let result = '100006';
    if (this.nowCar != undefined && this.nowCar.doorVisor != undefined) {
      result = this.nowCar.doorVisor;
    }
    return result;
  }

  // PC版料金シミュレーション用 シンプルプランの選択中 ドアバイザー
  @computed
  get nowSelectSimpleDoorVisor(): string {
    let result = '100006';
    if (this.simplePlanSelect != undefined && this.simplePlanSelect.doorVisor != undefined) {
      result = this.simplePlanSelect.doorVisor;
    }
    return result;
  }

  // PC版料金シミュレーション用 おすすめプランの選択中 ドアバイザー
  @computed
  get nowSelectRecommendDoorVisor(): string {
    let result = '100006';
    if (this.recommendPlanSelect != undefined && this.recommendPlanSelect.doorVisor != undefined) {
      result = this.recommendPlanSelect.doorVisor;
    }
    return result;
  }

  // PC版料金シミュレーション用 よくばりプランの選択中 ドアバイザー
  @computed
  get nowSelectRichDoorVisor(): string {
    let result = '100006';
    if (this.richPlanSelect != undefined && this.richPlanSelect.doorVisor != undefined) {
      result = this.richPlanSelect.doorVisor;
    }
    return result;
  }

  @computed
  get nowSelectWantNo(): string {
    let result = '100005';
    if (this.nowCar != undefined && this.nowCar.wantNo != undefined) {
      result = this.nowCar.wantNo;
    }
    return result;
  }

  // PC版料金シミュレーション用 シンプルプランの選択中 希望ナンバー
  @computed
  get nowSelectSimpleWantNo(): string {
    let result = '100005';
    if (this.simplePlanSelect != undefined && this.simplePlanSelect.wantNo != undefined) {
      result = this.simplePlanSelect.wantNo;
    }
    return result;
  }

  // PC版料金シミュレーション用 おすすめプランの選択中 希望ナンバー
  @computed
  get nowSelectRecommendWantNo(): string {
    let result = '100005';
    if (this.recommendPlanSelect != undefined && this.recommendPlanSelect.wantNo != undefined) {
      result = this.recommendPlanSelect.wantNo;
    }
    return result;
  }

  // PC版料金シミュレーション用 よくばりプランの選択中 希望ナンバー
  @computed
  get nowSelectRichWantNo(): string {
    let result = '100005';
    if (this.richPlanSelect != undefined && this.richPlanSelect.wantNo != undefined) {
      result = this.richPlanSelect.wantNo;
    }
    return result;
  }

  @computed
  get nowSelectWantCar(): string {
    if (this.nowCar != undefined && this.nowCar.wantCar === 'yes') {
      return this.nowCar.wantCar;
    }
    return 'no';
  }

  // PC版料金シミュレーション用 シンプルプランの選択中 もらえるオプション
  @computed
  get nowSelectSimpleWantCar(): string {
    if (this.simplePlanSelect != undefined && this.simplePlanSelect.wantCar === 'yes') {
      return this.simplePlanSelect.wantCar;
    }
    return 'no';
  }

  // PC版料金シミュレーション用 おすすめプランの選択中 もらえるオプション
  @computed
  get nowSelectRecommendWantCar(): string {
    if (this.recommendPlanSelect != undefined && this.recommendPlanSelect.wantCar === 'yes') {
      return this.recommendPlanSelect.wantCar;
    }
    return 'no';
  }

  // PC版料金シミュレーション用 よくばりプランの選択中 もらえるオプション
  @computed
  get nowSelectRichWantCar(): string {
    if (this.richPlanSelect != undefined && this.richPlanSelect.wantCar === 'yes') {
      return this.richPlanSelect.wantCar;
    }
    return 'no';
  }

  @computed
  get nowSelectMaintenance(): string | undefined {
    if (this.nowCar && this.nowCar.maintenancePlan) {
      return NEWCAR_CONST.MAINTENANCE.PLAN_NAME(this.nowCar.maintenancePlan);
    }
    return undefined;
  }

  // 現在の条件での金額算出
  @computed
  get nowSelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return nowSelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 11年での金額算出
  @computed
  get term11SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term11SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 10年での金額算出
  @computed
  get term10SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term10SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 9年での金額算出
  @computed
  get term9SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term9SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 8年での金額算出
  @computed
  get term8SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term8SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 7年での金額算出
  @computed
  get term7SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term7SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 6年での金額算出
  @computed
  get term6SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term6SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 5年での金額算出
  @computed
  get term5SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term5SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 4年での金額算出
  @computed
  get term4SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term4SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 3年での金額算出
  @computed
  get term3SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term3SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 2年での金額算出
  @computed
  get term2SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term2SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 1年での金額算出
  @computed
  get term1SelectPrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return term1SelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // 現在の条件での金額算出（ネット割なし）
  @computed
  get nowSelectBasePrice(): number | undefined {
    if (this.nowCar && this.nowCar.gradeId) {
      return nowSelectPrice(this.nowCar, this.nowGrades, this.nowMaintenanceType, true);
    }
    return undefined;
  }

  // SP版料金シミュレーション用 オススメプランの選択中条件での金額算出（ネット割なし）
  @computed
  get nowSelectRecommendSPPrice(): number | undefined {
    if (this.recommendSPPlanSelect && this.recommendSPPlanSelect.gradeId) {
      return nowSelectPrice(this.recommendSPPlanSelect, this.nowGrades, this.nowMaintenanceType, true);
    }
    return undefined;
  }

  // PC版料金シミュレーション用 シンプルプランの選択中条件での金額算出
  @computed
  get nowSelectSimplePrice(): number | undefined {
    if (this.simplePlanSelect && this.simplePlanSelect.gradeId) {
      return nowSelectPrice(this.simplePlanSelect, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // PC版料金シミュレーション用 おすすめプランの選択中条件での金額算出
  @computed
  get nowSelectRecommendPrice(): number | undefined {
    if (this.recommendPlanSelect && this.recommendPlanSelect.gradeId) {
      return nowSelectPrice(this.recommendPlanSelect, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  // PC版料金シミュレーション用 よくばりプランの選択中条件での金額算出
  @computed
  get nowSelectRichPrice(): number | undefined {
    if (this.richPlanSelect && this.richPlanSelect.gradeId) {
      return nowSelectPrice(this.richPlanSelect, this.nowGrades, this.nowMaintenanceType);
    }
    return undefined;
  }

  /**
     * シミュレーション結果保存用、ボディカラーの情報・値段を返却
     *
     * @readonly
     * @type {(SelectBodyColor | undefined)}
     * @memberof SimulationManager
     */
  @computed
  get nowSimulationBodyColor(): SelectBodyColor | undefined {
    const { nowCar } = this;
    const defaultTerm = NEWCAR_CONST.TERM.ID.YEAR_11;
    if (nowCar != undefined && searchCarStore.allBodyColorList != undefined) {
      // グレードの特定
      const targetGradeId = nowCar.gradeId;
      const targetGrade = (this.nowGrades.find((grade) => (grade.id == targetGradeId)));
      let targetBodyColor: any;
      let isSelected = false;
      if (nowCar.bodyColorId != undefined) {
        if (targetGrade && targetGrade.bodyColors) {
          // ボディカラーの特定
          targetBodyColor = (targetGrade.bodyColors.find((bodyColr) => (bodyColr.id == nowCar.bodyColorId)));
          isSelected = true;
        }
      } else if (targetGrade && targetGrade.bodyColors) {
        const bodyColorList = selectedGradeBodyColorList(targetGrade);
        targetBodyColor = bodyColorList[0];
      }

      if (targetBodyColor) {
        // ボディカラーInfoIdの特定
        const targetBodyColorInfo = (searchCarStore.allBodyColorList.find((bodyColr) => (bodyColr.id == targetBodyColor.bodyColorInfoId)));
        // ボディカラー料金の特定
        const bodyColorFee: any = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === targetBodyColor.optionFeeId);
        if (targetBodyColorInfo) {
          return {
            bodyColorId: targetBodyColor.id,
            bodyColorName: targetBodyColorInfo.bodyColorName,
            colorCode1: targetBodyColorInfo.colorCode1,
            colorCode2: targetBodyColorInfo.colorCode2,
            leasingFee: bodyColorFee && bodyColorFee[`leasingFee${(nowCar.term || defaultTerm) * 12}`],
            supportFee: bodyColorFee && bodyColorFee[`supportFee${(nowCar.term || defaultTerm) * 12}`],
            isSelected,
          };
        }
      }
    }
    return undefined;
  }

  /**
   * シミュレーション結果保存用、オプション・メンテナンスプラン・もらえるオプションの選択肢・値段を返却
   *
   * @readonly
   * @type {{carNavi?: SelectOption, etc?: SelectOption, backCamera?: SelectOption, floorMat?: SelectOption, wantNo?: SelectOption, doorVisor?: SelectOption, maintenancePlan?: IMaintenancePlan, wantCar?: SelectOption}}
   * @memberof SimulationManager
   * */
  @computed
  get nowSimulationOpstions(): {carNavi?: SelectOption, etc?: SelectOption, backCamera?: SelectOption, floorMat?: SelectOption, wantNo?: SelectOption, doorVisor?: SelectOption, maintenancePlan?: IMaintenancePlan, wantCar?: SelectOption} {
    const result:{carNavi?: SelectOption, etc?: SelectOption, backCamera?: SelectOption, floorMat?: SelectOption, wantNo?: SelectOption, doorVisor?: SelectOption, maintenancePlan?: IMaintenancePlan, wantCar?: SelectOption} = {};

    const defaultTerm = NEWCAR_CONST.TERM.ID.YEAR_11;
    if (this.nowCar != undefined) {
      // グレードの特定
      const targetGradeId = this.nowCar.gradeId;
      const targetGrade = (this.nowGrades.find((grade) => (grade.id == targetGradeId)));

      // option_type-3=カーナビ オプションID
      if (this.nowCar && this.nowCar.carNavi) {
        const carNaviOption = targetGrade && targetGrade.options
                && targetGrade.options.find((item) => item.id === (this.nowCar && this.nowCar.carNavi));
        if (carNaviOption) {
          const carNaviFee = (searchCarStore.allOptionFeeList as OptionFee[])
            .find((item) => item.id === carNaviOption.optionFeeId) as {[key: string]: number};
          const carNaviOptionInfo = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === carNaviOption.optionInfoId);
          result.carNavi = {
            id: this.nowCar.carNavi,
            name: carNaviOptionInfo && carNaviOptionInfo.name,
            isStandard: carNaviOption.isStandard,
            leasingFee: carNaviFee && carNaviFee[`leasingFee${(this.nowCar.term || defaultTerm) * 12}`],
            supportFee: carNaviFee && carNaviFee[`supportFee${(this.nowCar.term || defaultTerm) * 12}`],
            isSelected: true,
          };
        }
      }

      // option_type-4=ETC オプションID
      if (this.nowCar && this.nowCar.etc && this.nowCar.etc) {
        const etcOption = targetGrade && targetGrade.options
                && targetGrade.options.find((item) => item.id === (this.nowCar && this.nowCar.etc));
        if (etcOption) {
          const etcFee = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === etcOption.optionFeeId) as {[key: string]: number};
          const etcOptionInfo = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === etcOption.optionInfoId);
          result.etc = {
            id: this.nowCar.etc,
            name: etcOptionInfo && etcOptionInfo.name,
            isStandard: etcOption.isStandard,
            leasingFee: etcFee && etcFee[`leasingFee${(this.nowCar.term || defaultTerm) * 12}`],
            supportFee: etcFee && etcFee[`supportFee${(this.nowCar.term || defaultTerm) * 12}`],
            isSelected: true,
          };
        }
      }

      // option_type-5=バックカメラ オプションID
      if (this.nowCar && this.nowCar.backCamera) {
        const backCameraOption = targetGrade && targetGrade.options
                && targetGrade.options.find((item) => item.id === (this.nowCar && this.nowCar.backCamera));
        if (backCameraOption) {
          const backCameraFee = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === backCameraOption.optionFeeId) as {[key: string]: number};
          const backCameraOptionInfo = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === backCameraOption.optionInfoId);
          result.backCamera = {
            id: this.nowCar.backCamera,
            name: backCameraOptionInfo && backCameraOptionInfo.name,
            isStandard: backCameraOption.isStandard,
            leasingFee: backCameraFee && backCameraFee[`leasingFee${(this.nowCar.term || defaultTerm) * 12}`],
            supportFee: backCameraFee && backCameraFee[`supportFee${(this.nowCar.term || defaultTerm) * 12}`],
            isSelected: true,
          };
        }
      }

      // option_type-6=フロアマット オプションID
      if (this.nowCar && this.nowCar.floorMat) {
        const floorMatOption = targetGrade && targetGrade.options
                && targetGrade.options.find((item) => item.id === (this.nowCar && this.nowCar.floorMat));
        if (floorMatOption) {
          const floorMatFee = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === floorMatOption.optionFeeId) as {[key: string]: number};
          const floorMatOptionInfo = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === floorMatOption.optionInfoId);
          result.floorMat = {
            id: this.nowCar.floorMat,
            name: floorMatOptionInfo && floorMatOptionInfo.name,
            isStandard: floorMatOption.isStandard,
            leasingFee: floorMatFee && floorMatFee[`leasingFee${(this.nowCar.term || defaultTerm) * 12}`],
            supportFee: floorMatFee && floorMatFee[`supportFee${(this.nowCar.term || defaultTerm) * 12}`],
            isSelected: true,
          };
        }
      }

      // option_type-7=希望ナンバー オプションID
      if (this.nowCar && this.nowCar.wantNo) {
        const wantNoOption = targetGrade && targetGrade.options
                && targetGrade.options.find((item) => item.id === (this.nowCar && this.nowCar.wantNo));
        if (wantNoOption) {
          const wantNoFee = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === wantNoOption.optionFeeId) as {[key: string]: number};
          const wantNoOptionInfo: any = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === wantNoOption.optionInfoId);
          result.wantNo = {
            id: this.nowCar.wantNo,
            name: wantNoOptionInfo && wantNoOptionInfo.name,
            isStandard: wantNoOption.isStandard,
            leasingFee: wantNoFee && wantNoFee[`leasingFee${(this.nowCar.term || defaultTerm) * 12}`],
            supportFee: wantNoFee && wantNoFee[`supportFee${(this.nowCar.term || defaultTerm) * 12}`],
            isSelected: true,
          };
        }
      }

      // option_type-8=ドアバイザー オプションID
      if (this.nowCar && this.nowCar.doorVisor) {
        if (!this.nowCar.dontHaveDoorVisor) {
          const doorVisorOption: any = targetGrade && targetGrade.options
                    && targetGrade.options.find((item) => item.id === (this.nowCar && this.nowCar.doorVisor));
          if (doorVisorOption) {
            const doorVisorFee = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === doorVisorOption.optionFeeId) as {[key: string]: number};
            const doorVisorOptionInfo = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === doorVisorOption.optionInfoId);
            result.doorVisor = {
              id: this.nowCar.doorVisor,
              name: doorVisorOptionInfo && doorVisorOptionInfo.name,
              isStandard: doorVisorOption.isStandard,
              leasingFee: doorVisorFee && doorVisorFee[`leasingFee${(this.nowCar.term || defaultTerm) * 12}`],
              supportFee: doorVisorFee && doorVisorFee[`supportFee${(this.nowCar.term || defaultTerm) * 12}`],
              isSelected: true,
            };
          }
        }
      }

      // もらえるオプション
      const termId = (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;
      if (this.nowCar.wantCar === 'yes' && termId >= NEWCAR_CONST.TERM.ID.YEAR_7) {
        result.wantCar = {
          isStandard: false,
          price: getFreeTransferDiffLeasingFeeTaxedPrice(targetGrade, termId),
          isSelected: true,
        };
      }

      // メンテナンス
      if (this.nowCar.maintenancePlan) {
        result.maintenancePlan = NEWCAR_DEFINE.MAINTENANCE.PLANS(
          this.nowMaintenanceType,
          termId,
        )[this.nowCar.maintenancePlan];
      }
    }

    return result;
  }

  @action
  saveEntryBreakdown(plan?: NEWCAR_CONST.SIMULATION_PLAN.ID) {
    let memo = '';
    let hasDoorVisor = false; // グレード未選択時のドアバイザー装着不可車のチェック
    let targetGrade: VehicleGrade | undefined;
    let defaultCarNavi: any;
    const defaultTerm = NEWCAR_CONST.TERM.ID.YEAR_11;

    let selection = this.nowCar;
    if (plan === NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      memo += `|プラン：${NEWCAR_CONST.PLAN_PATTERN.NAME.MAIN_SIMPLE}`;
      selection = this.simplePlanSelect;
    } else if (plan === NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      memo += `|プラン：${NEWCAR_CONST.PLAN_PATTERN.NAME.MAIN_RECOMMEND_PC}`;
      selection = this.recommendPlanSelect;
    } else if (plan === NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      memo += `|プラン：${NEWCAR_CONST.PLAN_PATTERN.NAME.MAIN_RICH}`;
      selection = this.richPlanSelect;
    } else if (plan === NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL) {
      memo += `|プラン：${NEWCAR_CONST.PLAN_PATTERN.NAME.MAIN_ORIGINAL}`;
    } else if (plan === NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP) {
      memo += `|プラン：${NEWCAR_CONST.PLAN_PATTERN.NAME.MAIN_RECOMMEND_SP}`;
    } else if (plan === NEWCAR_CONST.SIMULATION_PLAN.ID.TRIAL) {
      memo += `|プラン：${NEWCAR_CONST.PLAN_PATTERN.NAME.MAIN_TRIAL}|プランメモ：相談申込`;
    }

    // 流入元分類
    const referrerType = NEWCAR_CONST.APPLICATION_TYPE.REFERRER_TYPE.MAIN;
    // 車種状態
    const carCondition = NEWCAR_CONST.APPLICATION_TYPE.CAR_CONDITION.NEW_CAR;
    // 車種選択状況
    let carSelectStatus = '';
    switch (plan) {
      case NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE:
      case NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND:
      case NEWCAR_CONST.SIMULATION_PLAN.ID.RICH:
      case NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP:
        carSelectStatus = NEWCAR_CONST.APPLICATION_TYPE.CAR_SELECT.CAR_MODEL;
        break;
      case NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL: {
        // プラン詳細
        const planDetails = NEWCAR_CONST.APPLICATION_TYPE.PLAN_DETAIL.INDIVIDUAL_COST_SIMULATIONS;
        entryBreakdownManagerStore.updateEntryBreakdown({ field: 'planDetails', payload: planDetails });
        carSelectStatus = NEWCAR_CONST.APPLICATION_TYPE.CAR_SELECT.CAR_MODEL;
        break;
      }
      case NEWCAR_CONST.SIMULATION_PLAN.ID.TRIAL:
        carSelectStatus = NEWCAR_CONST.APPLICATION_TYPE.CAR_SELECT.CONSULTATION;
        break;
      default:
        break;
    }
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'referrerType', payload: referrerType });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'carCondition', payload: carCondition });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'carSelectStatus', payload: carSelectStatus });

    if (selection === undefined) {
      // グレード未選択時
      targetGrade = listupGradeName(this.nowGrades)[0].defaultGrade;
      memo += '|グレード：未選択';
      if (targetGrade && targetGrade.bodyColors) {
        const bodyColorList = selectedGradeBodyColorList(targetGrade);
        const bodyColor = bodyColorList[0] || {};
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'bodyColor',
          payload: {
            id: bodyColor.id,
            name: bodyColor && bodyColor.bodyColorName,
            leasingFee: bodyColor && bodyColor.leasingFee,
            supportFee: bodyColor && bodyColor.supportFee,
          },
        });
        memo += '|ボディカラー：未選択';
      }

      if (targetGrade && targetGrade.options && searchCarStore.allOptionInfoList) {
        for (const targetOption of targetGrade.options) {
          const nowOptions = (searchCarStore.allOptionInfoList.find((option) => (option.id == targetOption.optionInfoId)));
          if (nowOptions && nowOptions.optionType === 'カーナビ') {
            const carNaviFee: any = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === targetOption.optionFeeId);
            const carNaviOptionInfo: any = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === targetOption.optionInfoId);
            defaultCarNavi = {
              id: targetOption.id,
              name: carNaviOptionInfo.name,
              leasingFee: carNaviFee && carNaviFee[`leasingFee${defaultTerm * 12}`],
              supportFee: carNaviFee && carNaviFee[`supportFee${defaultTerm * 12}`],
            };
          }
          if (nowOptions && nowOptions.optionType === 'ドアバイザー') {
          // ドアバイザー存在
            hasDoorVisor = true;
          }
        }
      }
    } else {
    // option_type-1=ボディーカラーID
      const gradeId = selection.gradeId;
      targetGrade = (this.nowGrades.find((grade) => (grade.id == gradeId)));
      if (selection.bodyColorId) {
        const bodyColor = ((targetGrade && targetGrade.bodyColors) || []).find((item) => item.id === (selection as any).bodyColorId) || {};
        const bodyColorInfo = (searchCarStore.allBodyColorList as BodyColorInfo[]).find((item) => item.id === bodyColor.bodyColorInfoId);
        const bodyColorFee: any = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === bodyColor.optionFeeId);
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'bodyColor',
          payload: {
            id: selection.bodyColorId,
            name: bodyColorInfo && bodyColorInfo.bodyColorName,
            leasingFee: bodyColorFee && bodyColorFee[`leasingFee${(selection.term || defaultTerm) * 12}`],
            supportFee: bodyColorFee && bodyColorFee[`supportFee${(selection.term || defaultTerm) * 12}`],
          },
        });
      } else if (selection && selection.gradeId) {
        if (targetGrade && targetGrade.bodyColors) {
          const bodyColorList = selectedGradeBodyColorList(targetGrade);
          const bodyColor = bodyColorList[0] || {};
          entryBreakdownManagerStore.updateEntryBreakdown({
            field: 'bodyColor',
            payload: {
              id: bodyColor.id,
              name: bodyColor && bodyColor.bodyColorName,
              leasingFee: bodyColor && bodyColor.leasingFee,
              supportFee: bodyColor && bodyColor.supportFee,
            },
          });
          memo += '|ボディカラー：未選択';
        }
      }
    }

    // 対象車種更新
    const car: CarDigest | undefined = searchCarStore.allCarDigests
        && searchCarStore.allCarDigests.find((c) => c.id__normalized.toString() === this.lastEntryId);
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'car', payload: car });

    // 対象グレード更新
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'grade', payload: targetGrade });

    // option_type-3=カーナビ オプションID
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'carNavi', payload: undefined });
    if (selection && selection.carNavi) {
      const carNaviOption = targetGrade && targetGrade.options
            && targetGrade.options.find((item) => item.id === (selection && selection.carNavi));
      if (carNaviOption) {
        const carNaviFee = (searchCarStore.allOptionFeeList as OptionFee[])
          .find((item) => item.id === carNaviOption.optionFeeId) as {[key: string]: number};
        const carNaviOptionInfo = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === carNaviOption.optionInfoId);
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'carNavi',
          payload: {
            id: selection.carNavi,
            name: carNaviOptionInfo && carNaviOptionInfo.name,
            isStandard: carNaviOption.isStandard,
            leasingFee: carNaviFee && carNaviFee[`leasingFee${(selection.term || defaultTerm) * 12}`],
            supportFee: carNaviFee && carNaviFee[`supportFee${(selection.term || defaultTerm) * 12}`],
          },
        });
      }
    } else {
    // todo カーナビは有りにしたい
      if (defaultCarNavi) {
        entryBreakdownManagerStore.updateEntryBreakdown({ field: 'carNavi', payload: defaultCarNavi });
      } else {
        entryBreakdownManagerStore.updateEntryBreakdown({ field: 'carNavi', payload: undefined });
      }
      memo += '|カーナビ：未選択';
    }
    // option_type-4=ETC オプションID
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'etc', payload: undefined });
    if (selection && selection.etc && selection.etc) {
      const etcOption = targetGrade && targetGrade.options
            && targetGrade.options.find((item) => item.id === (selection && selection.etc));
      if (etcOption) {
        const etcFee = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === etcOption.optionFeeId) as {[key: string]: number};
        const etcOptionInfo = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === etcOption.optionInfoId);
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'etc',
          payload: {
            id: selection.etc,
            name: etcOptionInfo && etcOptionInfo.name,
            isStandard: etcOption.isStandard,
            leasingFee: etcFee && etcFee[`leasingFee${(selection.term || defaultTerm) * 12}`],
            supportFee: etcFee && etcFee[`supportFee${(selection.term || defaultTerm) * 12}`],
          },
        });
      }
    } else {
      const defaultOption = targetGrade?.options?.find((item) => {
        const optionInfo = searchCarStore.allOptionInfoList?.find(
          (info) => info.id === item.optionInfoId,
        );
        return optionInfo?.optionType === 'ETC';
      });
      // 選択中なし、標準装備の場合
      if (defaultOption?.isStandard) {
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'etc',
          payload: {
            id: defaultOption.id?.toString(),
            name: '標準装備',
            isStandard: true,
            leasingFee: 0,
            supportFee: 0,
          },
        });
      } else {
        memo += '|ETC：未選択';
      }
    }
    // option_type-5=バックカメラ オプションID
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'backCamera', payload: undefined });
    if (selection && selection.backCamera) {
      const backCameraOption = targetGrade && targetGrade.options
            && targetGrade.options.find((item) => item.id === (selection && selection.backCamera));
      if (backCameraOption) {
        const backCameraFee = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === backCameraOption.optionFeeId) as {[key: string]: number};
        const backCameraOptionInfo = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === backCameraOption.optionInfoId);
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'backCamera',
          payload: {
            id: selection.backCamera,
            name: backCameraOptionInfo && backCameraOptionInfo.name,
            isStandard: backCameraOption.isStandard,
            leasingFee: backCameraFee && backCameraFee[`leasingFee${(selection.term || defaultTerm) * 12}`],
            supportFee: backCameraFee && backCameraFee[`supportFee${(selection.term || defaultTerm) * 12}`],
          },
        });
      }
    } else {
      const defaultOption = targetGrade?.options?.find((item) => {
        const optionInfo = searchCarStore.allOptionInfoList?.find(
          (info) => info.id === item.optionInfoId,
        );
        return optionInfo?.optionType === 'バックカメラ';
      });
      // 選択中なし、標準装備の場合
      if (defaultOption?.isStandard) {
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'backCamera',
          payload: {
            id: defaultOption.id?.toString(),
            name: '標準装備',
            isStandard: true,
            leasingFee: 0,
            supportFee: 0,
          },
        });
      } else {
        memo += '|バックカメラ：未選択';
      }
    }
    // option_type-6=フロアマット オプションID
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'floorMat', payload: undefined });
    if (selection && selection.floorMat) {
      const floorMatOption = targetGrade && targetGrade.options
            && targetGrade.options.find((item) => item.id === (selection && selection.floorMat));
      if (floorMatOption) {
        const floorMatFee = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === floorMatOption.optionFeeId) as {[key: string]: number};
        const floorMatOptionInfo = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === floorMatOption.optionInfoId);
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'floorMat',
          payload: floorMatOption && {
            id: selection.floorMat,
            name: floorMatOptionInfo && floorMatOptionInfo.name,
            isStandard: floorMatOption.isStandard,
            leasingFee: floorMatFee && floorMatFee[`leasingFee${(selection.term || defaultTerm) * 12}`],
            supportFee: floorMatFee && floorMatFee[`supportFee${(selection.term || defaultTerm) * 12}`],
          },
        });
      }
    } else {
      const defaultOption = targetGrade?.options?.find((item) => {
        const optionInfo = searchCarStore.allOptionInfoList?.find(
          (info) => info.id === item.optionInfoId,
        );
        return optionInfo?.optionType === 'フロアマット';
      });
      // 選択中なし、標準装備の場合
      if (defaultOption?.isStandard) {
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'floorMat',
          payload: {
            id: defaultOption.id?.toString(),
            name: '標準装備',
            isStandard: true,
            leasingFee: 0,
            supportFee: 0,
          },
        });
      } else {
        memo += '|フロアマット：未選択';
      }
    }
    // option_type-7=希望ナンバー オプションID
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'wantNo', payload: undefined });
    if (selection && selection.wantNo) {
      const wantNoOption = targetGrade && targetGrade.options
            && targetGrade.options.find((item) => item.id === (selection && selection.wantNo));
      if (wantNoOption) {
        const wantNoFee = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === wantNoOption.optionFeeId) as {[key: string]: number};
        const wantNoOptionInfo: any = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === wantNoOption.optionInfoId);
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'wantNo',
          payload: wantNoOption && {
            id: selection.wantNo,
            name: wantNoOptionInfo && wantNoOptionInfo.name,
            isStandard: wantNoOption.isStandard,
            leasingFee: wantNoFee && wantNoFee[`leasingFee${(selection.term || defaultTerm) * 12}`],
            supportFee: wantNoFee && wantNoFee[`supportFee${(selection.term || defaultTerm) * 12}`],
          },
        });
      }
    } else {
      memo += '|希望ナンバー：未選択';
    }
    // option_type-8=ドアバイザー オプションID
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'doorVisor', payload: undefined });
    if (selection && selection.doorVisor) {
      if (selection.dontHaveDoorVisor) {
        memo += '|ドアバイザー：装着不可';
      } else {
        const doorVisorOption: any = targetGrade && targetGrade.options
                && targetGrade.options.find((item) => item.id === (selection && selection.doorVisor));
        if (doorVisorOption) {
          const doorVisorFee = (searchCarStore.allOptionFeeList as OptionFee[]).find((item) => item.id === doorVisorOption.optionFeeId) as {[key: string]: number};
          const doorVisorOptionInfo = (searchCarStore.allOptionInfoList as OptionInfo[]).find((item) => item.id === doorVisorOption.optionInfoId);
          entryBreakdownManagerStore.updateEntryBreakdown({
            field: 'doorVisor',
            payload: doorVisorOption && {
              id: selection.doorVisor,
              name: doorVisorOptionInfo && doorVisorOptionInfo.name,
              isStandard: doorVisorOption.isStandard,
              leasingFee: doorVisorFee && doorVisorFee[`leasingFee${(selection.term || defaultTerm) * 12}`],
              supportFee: doorVisorFee && doorVisorFee[`supportFee${(selection.term || defaultTerm) * 12}`],
            },
          });
        }
      }
    } else if (hasDoorVisor === false) {
      memo += '|ドアバイザー：装着不可';
    } else {
      const defaultOption = targetGrade?.options?.find((item) => {
        const optionInfo = searchCarStore.allOptionInfoList?.find(
          (info) => info.id === item.optionInfoId,
        );
        return optionInfo?.optionType === 'ドアバイザー';
      });
      // 選択中なし、標準装備の場合
      if (defaultOption?.isStandard) {
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'doorVisor',
          payload: {
            id: defaultOption.id?.toString(),
            name: '標準装備',
            isStandard: true,
            leasingFee: 0,
            supportFee: 0,
          },
        });
      } else {
        memo += '|ドアバイザー：未選択';
      }
    }

    // もらえるオプション
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'wantCar', payload: undefined });
    const termId = selection?.term || NEWCAR_CONST.TERM.ID.YEAR_11;
    if (selection?.wantCar !== 'no' && termId >= NEWCAR_CONST.TERM.ID.YEAR_7) {
      const wantCar = new WantCarBreakdown();
      wantCar.price = getFreeTransferDiffLeasingFeeTaxedPrice(targetGrade, termId);
      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'wantCar', payload: wantCar });
      memo += '|もらえるオプション：希望';
    }

    // 賃貸期間
    if (selection === undefined || isNaN(Number(selection.term))) {
      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'term', payload: defaultTerm });
      memo += '|期間：未選択';
    } else {
      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'term', payload: selection.term });
    }

    // メンテナンス
    let maintenancePlan :IMaintenancePlan;
    if (selection && selection.maintenancePlan && this.nowMaintenanceType) {
      maintenancePlan = NEWCAR_DEFINE.MAINTENANCE.PLANS(
        this.nowMaintenanceType,
        termId,
      )[selection.maintenancePlan];
      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'maintenancePlan', payload: maintenancePlan });
    } else {
      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'maintenancePlan', payload: undefined });
    }
    if (selection === undefined || selection.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED) {
      memo += '|メンテ：未選択';
    } else if (selection.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) {
      memo += '|メンテ：プラチナ';
    } else if (selection.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD) {
      memo += '|メンテ：ゴールド';
    } else if (selection.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER) {
      memo += '|メンテ：シルバー';
    } else if (selection.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.NONE) {
      memo += '|メンテ：なし';
    }

    // 備考欄
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'memo', payload: memo });

    // セッションストレージに保存
    entryBreakdownManagerStore.saveToSessionStorage();
  }

  // エンジンリスト
  @computed
  get engineList(): Array<{ label: string; value: CustomerSelection['engine'] }> {
    // 車種未選択時は選択不可
    if (this.nowCar === undefined) { return []; }

    // 該当者車種がどのエンジンを持つかチェック
    const hasGas = (this.nowGrades.find((grade) => (grade.engine == undefined)));
    const hasHybrid = (this.nowGrades.find((grade) => (grade.engine == NEWCAR_CONST.ENGINE.NAME[NEWCAR_CONST.ENGINE.ID.IS_HYBRID])));
    const hasTurbo = (this.nowGrades.find((grade) => (grade.engine == NEWCAR_CONST.ENGINE.NAME[NEWCAR_CONST.ENGINE.ID.IS_TURBO])));
    // エンジンリストを作成
    const result: Array<{ label: string; value: CustomerSelection['engine'] }> = [];
    if (hasGas != undefined) {
      result.push({ label: NEWCAR_CONST.ENGINE.NAME[NEWCAR_CONST.ENGINE.ID.IS_GAS], value: NEWCAR_CONST.ENGINE.ID.IS_GAS });
    }
    if (hasHybrid != undefined) {
      result.push({ label: NEWCAR_CONST.ENGINE.NAME[NEWCAR_CONST.ENGINE.ID.IS_HYBRID], value: NEWCAR_CONST.ENGINE.ID.IS_HYBRID });
    }
    if (hasTurbo != undefined) {
      result.push({ label: NEWCAR_CONST.ENGINE.NAME[NEWCAR_CONST.ENGINE.ID.IS_TURBO], value: NEWCAR_CONST.ENGINE.ID.IS_TURBO });
    }
    return result;
  }

  // 現在選択中の車種、エンジンに応じたグレード一覧
  @computed
  get gradeList(): IGradeNameList[] {
    if (!this.nowGrades || !this.nowCar) {
      return [];
    }
    return listupGradeName(this.nowGrades, this.nowCar);
  }

  // 駆動一覧
  @computed
  get kudouList(): Array<{ label: string; value: CustomerSelection['kudou']; disabled?: boolean }> {
    if (this.nowGrades) {
      let grades = this.nowGrades;
      // エンジンタイプで絞り込み
      if (this.nowCar?.engine) {
        grades = grades.filter((grade) => (grade.engine || 'ガソリン') === NEWCAR_CONST.ENGINE.NAME[this.nowCar!.engine!]);
      }
      // ミッションで絞り込み
      if (this.nowCar?.mission) {
        grades = grades.filter((grade) => getMissionType(grade.mission) === this.nowCar!.mission);
      }
      const kudouList = grades.map((grade) => grade.kudou || '');
      if (kudouList.includes('2WD') && kudouList.includes('4WD')) {
        return [
          { label: '2WD', value: 2 },
          { label: '4WD', value: 4 },
        ];
      }
      if (!kudouList.includes('2WD') && kudouList.includes('4WD')) {
        // 4WDのみの場合は2WDを非活性
        return [
          { label: '2WD', value: 2, disabled: true },
          { label: '4WD', value: 4 },
        ];
      }
    }
    return [];
  }

  // ミッション一覧
  @computed
  get missionList(): Array<{ label: string; value: CustomerSelection['mission']; disabled?: boolean }> {
    if (this.nowGrades) {
      let grades = this.nowGrades;
      // エンジンタイプで絞り込み
      if (this.nowCar?.engine) {
        grades = grades.filter((grade) => (grade.engine || 'ガソリン') === NEWCAR_CONST.ENGINE.NAME[this.nowCar!.engine!]);
      }
      // 駆動で絞り込み
      if (this.nowCar?.kudou) {
        grades = grades.filter((grade) => grade.kudou === NEWCAR_CONST.KUDOU.NAME[this.nowCar!.kudou!]);
      }
      const missionTypeList = grades.map((grade) => getMissionType(grade.mission || ''));
      if (missionTypeList.includes('AT') && missionTypeList.includes('MT')) {
        return [
          { label: 'オートマチック', value: 'AT' },
          { label: 'マニュアル', value: 'MT' },
        ];
      }
      if (!missionTypeList.includes('AT') && missionTypeList.includes('MT')) {
        // MTのみの場合はATを非活性
        return [
          { label: 'オートマチック', value: 'AT', disabled: true },
          { label: 'マニュアル', value: 'MT' },
        ];
      }
    }
    return [];
  }

  // 現在選択中のグレードで選択出来るボディカラーリスト
  @computed
  get bodyColorList(): Array<{ id: string, bodyColorInfoId: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string, price?: number, isChecked?: boolean }> {
    let result: Array<{ id: string, bodyColorInfoId: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string, price?: number, isChecked?: boolean }> = [];
    const target = this.nowCar;
    if (target) {
      const nowGrade = (this.nowGrades.find((grade) => (grade.id == target.gradeId)));
      if (nowGrade && nowGrade.bodyColors && searchCarStore.allBodyColorList && searchCarStore.allOptionFeeList) {
        result = selectedGradeBodyColorList(nowGrade, (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11, this.nowCar && this.nowCar.bodyColorId && this.nowCar.bodyColorId);
      }
    }
    return result;
  }

  // PC版料金シミュレーション用 シンプルプラン 選択出来るボディカラーリスト
  @computed
  get simplePlansBodyColorList(): Array<{ id: string, bodyColorInfoId: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string, price?: number, isChecked?: boolean }> {
    let result: Array<{ id: string, bodyColorInfoId: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string, price?: number, isChecked?: boolean }> = [];
    const target = this.simplePlanSelect;
    if (target) {
      const nowGrade = (this.nowGrades.find((grade) => (grade.id == target.gradeId)));
      if (nowGrade && nowGrade.bodyColors && searchCarStore.allBodyColorList && searchCarStore.allOptionFeeList) {
        result = selectedGradeBodyColorList(nowGrade, (this.simplePlanSelect && this.simplePlanSelect.term) ? this.simplePlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11, this.simplePlanSelect && this.simplePlanSelect.bodyColorId && this.simplePlanSelect.bodyColorId);
      }
    }

    return result;
  }

  // PC版料金シミュレーション用 おすすめプラン 選択出来るボディカラーリスト
  @computed
  get recommendPlansBodyColorList(): Array<{ id: string, bodyColorInfoId: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string, price?: number, isChecked?: boolean }> {
    let result: Array<{ id: string, bodyColorInfoId: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string, price?: number, isChecked?: boolean }> = [];
    const target = this.recommendPlanSelect;
    if (target) {
      const nowGrade = (this.nowGrades.find((grade) => (grade.id == target.gradeId)));
      if (nowGrade && nowGrade.bodyColors && searchCarStore.allBodyColorList && searchCarStore.allOptionFeeList) {
        result = selectedGradeBodyColorList(nowGrade, (this.recommendPlanSelect && this.recommendPlanSelect.term) ? this.recommendPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11, this.recommendPlanSelect && this.recommendPlanSelect.bodyColorId && this.recommendPlanSelect.bodyColorId);
      }
    }

    return result;
  }

  // PC版料金シミュレーション用 よくばりプラン 選択出来るボディカラーリスト
  @computed
  get richPlansBodyColorList(): Array<{ id: string, bodyColorInfoId: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string, price?: number, isChecked?: boolean }> {
    let result: Array<{ id: string, bodyColorInfoId: string, bodyColorName?: string, colorCode1?: string, colorCode2?: string, price?: number, isChecked?: boolean }> = [];
    const target = this.richPlanSelect;
    if (target) {
      const nowGrade = (this.nowGrades.find((grade) => (grade.id == target.gradeId)));
      if (nowGrade && nowGrade.bodyColors && searchCarStore.allBodyColorList && searchCarStore.allOptionFeeList) {
        result = selectedGradeBodyColorList(nowGrade, (this.richPlanSelect && this.richPlanSelect.term) ? this.richPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11, this.richPlanSelect && this.richPlanSelect.bodyColorId && this.richPlanSelect.bodyColorId);
      }
    }

    return result;
  }

  @computed
  get carNaviList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'カーナビ', this.nowCar, this.nowGrades);
    if (this.nowCar && list.length === 1 && list[0].isStandard === true) {
      this.nowCar.carNavi = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 シンプルプラン 選択出来るカーナビ
  @computed
  get simplePlansCarNaviList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.simplePlanSelect && this.simplePlanSelect.term) ? this.simplePlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'カーナビ', this.simplePlanSelect, this.nowGrades);
    if (this.simplePlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.simplePlanSelect.carNavi = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 おすすめプラン 選択出来るカーナビ
  @computed
  get recommendPlansCarNaviList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.recommendPlanSelect && this.recommendPlanSelect.term) ? this.recommendPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'カーナビ', this.recommendPlanSelect, this.nowGrades);
    if (this.recommendPlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.recommendPlanSelect.carNavi = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 よくばりプラン 選択出来るカーナビ
  @computed
  get richPlansCarNaviList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.richPlanSelect && this.richPlanSelect.term) ? this.richPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'カーナビ', this.richPlanSelect, this.nowGrades);
    if (this.richPlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.richPlanSelect.carNavi = list[0].id;
    }
    return list;
  }

  @computed
  get etcList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'ETC', this.nowCar, this.nowGrades);
    if (this.nowCar && list.length === 1 && list[0].isStandard === true) {
      this.nowCar.etc = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 シンプルプラン 選択出来るETC
  @computed
  get simplePlansEtcList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.simplePlanSelect && this.simplePlanSelect.term) ? this.simplePlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'ETC', this.simplePlanSelect, this.nowGrades);
    if (this.simplePlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.simplePlanSelect.etc = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 おすすめプラン 選択出来るETC
  @computed
  get recommendPlansEtcList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.recommendPlanSelect && this.recommendPlanSelect.term) ? this.recommendPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'ETC', this.recommendPlanSelect, this.nowGrades);
    if (this.recommendPlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.recommendPlanSelect.etc = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 よくばりプラン 選択出来るETC
  @computed
  get richPlansEtcList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.richPlanSelect && this.richPlanSelect.term) ? this.richPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'ETC', this.richPlanSelect, this.nowGrades);
    if (this.richPlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.richPlanSelect.etc = list[0].id;
    }
    return list;
  }

  @computed
  get backCameraList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'バックカメラ', this.nowCar, this.nowGrades);
    if (this.nowCar && list.length === 1 && list[0].isStandard === true) {
      this.nowCar.backCamera = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 シンプルプラン 選択出来るバックカメラ
  @computed
  get simplePlansbackCameraList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.simplePlanSelect && this.simplePlanSelect.term) ? this.simplePlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'バックカメラ', this.simplePlanSelect, this.nowGrades);
    if (this.simplePlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.simplePlanSelect.backCamera = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 おすすめプラン 選択出来るバックカメラ
  @computed
  get recommendPlansbackCameraList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.recommendPlanSelect && this.recommendPlanSelect.term) ? this.recommendPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'バックカメラ', this.recommendPlanSelect, this.nowGrades);
    if (this.recommendPlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.recommendPlanSelect.backCamera = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 よくばりプラン 選択出来るバックカメラ
  @computed
  get richPlansbackCameraList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.richPlanSelect && this.richPlanSelect.term) ? this.richPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'バックカメラ', this.richPlanSelect, this.nowGrades);
    if (this.richPlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.richPlanSelect.backCamera = list[0].id;
    }
    return list;
  }

  @computed
  get doorVisorList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'ドアバイザー', this.nowCar, this.nowGrades);
    if (this.nowCar) {
      this.nowCar.dontHaveDoorVisor = (list.length === 0);
      if (list.length === 1 && list[0].isStandard === true) {
        this.nowCar.doorVisor = list[0].id;
      }
    }
    return list;
  }

  // PC版料金シミュレーション用 シンプルプラン 選択出来るドアバイザー
  @computed
  get simplePlansDoorVisorList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.simplePlanSelect && this.simplePlanSelect.term) ? this.simplePlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'ドアバイザー', this.simplePlanSelect, this.nowGrades);
    if (this.simplePlanSelect) {
      this.simplePlanSelect.dontHaveDoorVisor = (list.length === 0);
      if (list.length === 1 && list[0].isStandard === true) {
        this.simplePlanSelect.doorVisor = list[0].id;
      }
    }
    return list;
  }

  // PC版料金シミュレーション用 おすすめプラン 選択出来るドアバイザー
  @computed
  get recommendPlansDoorVisorList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.recommendPlanSelect && this.recommendPlanSelect.term) ? this.recommendPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'ドアバイザー', this.recommendPlanSelect, this.nowGrades);
    if (this.recommendPlanSelect) {
      this.recommendPlanSelect.dontHaveDoorVisor = (list.length === 0);
      if (list.length === 1 && list[0].isStandard === true) {
        this.recommendPlanSelect.doorVisor = list[0].id;
      }
    }
    return list;
  }

  // PC版料金シミュレーション用 よくばりプラン 選択出来るドアバイザー
  @computed
  get richPlansDoorVisorList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.richPlanSelect && this.richPlanSelect.term) ? this.richPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'ドアバイザー', this.richPlanSelect, this.nowGrades);
    if (this.richPlanSelect) {
      this.richPlanSelect.dontHaveDoorVisor = (list.length === 0);
      if (list.length === 1 && list[0].isStandard === true) {
        this.richPlanSelect.doorVisor = list[0].id;
      }
    }
    return list;
  }

  @computed
  get floorMatList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'フロアマット', this.nowCar, this.nowGrades);
    if (this.nowCar && list.length === 1 && list[0].isStandard === true) {
      this.nowCar.floorMat = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 シンプルプラン 選択出来るフロアマット
  @computed
  get simplePlansFloorMatList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.simplePlanSelect && this.simplePlanSelect.term) ? this.simplePlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'フロアマット', this.simplePlanSelect, this.nowGrades);
    if (this.simplePlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.simplePlanSelect.floorMat = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 おすすめプラン 選択出来るフロアマット
  @computed
  get recommendPlansFloorMatList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.recommendPlanSelect && this.recommendPlanSelect.term) ? this.recommendPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'フロアマット', this.recommendPlanSelect, this.nowGrades);
    if (this.recommendPlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.recommendPlanSelect.floorMat = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 よくばりプラン 選択出来るフロアマット
  @computed
  get richPlansFloorMatList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.richPlanSelect && this.richPlanSelect.term) ? this.richPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, 'フロアマット', this.richPlanSelect, this.nowGrades);
    if (this.richPlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.richPlanSelect.floorMat = list[0].id;
    }
    return list;
  }

  @computed
  get wantNoList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, '希望ナンバー', this.nowCar, this.nowGrades);
    if (this.nowCar && list.length === 1 && list[0].isStandard === true) {
      this.nowCar.wantNo = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 シンプルプラン 選択出来る希望ナンバー
  @computed
  get simplePlansWantNoList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.simplePlanSelect && this.simplePlanSelect.term) ? this.simplePlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, '希望ナンバー', this.simplePlanSelect, this.nowGrades);
    if (this.simplePlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.simplePlanSelect.wantNo = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 おすすめプラン 選択出来る希望ナンバー
  @computed
  get recommendPlansWantNoList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.recommendPlanSelect && this.recommendPlanSelect.term) ? this.recommendPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, '希望ナンバー', this.recommendPlanSelect, this.nowGrades);
    if (this.recommendPlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.recommendPlanSelect.wantNo = list[0].id;
    }
    return list;
  }

  // PC版料金シミュレーション用 よくばりプラン 選択出来る希望ナンバー
  @computed
  get richPlansWantNoList(): Array<{ id: string, notid: string, isStandard: boolean, optionInfoId: number, thumbnailFileName?: string, name?: string, description?: string, optionType?: string, price?: number, isChecked?: boolean }> {
    const term = (this.richPlanSelect && this.richPlanSelect.term) ? this.richPlanSelect.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    const list = optionList(term, '希望ナンバー', this.richPlanSelect, this.nowGrades);
    if (this.richPlanSelect && list.length === 1 && list[0].isStandard === true) {
      this.richPlanSelect.wantNo = list[0].id;
    }
    return list;
  }

  @computed
  get wantCarPrice(): number {
    return getFreeTransferDiffLeasingFeeTaxedPrice(simulationManagerStore.nowGrade, simulationManagerStore.nowSelectTerm);
  }

  // PC版料金シミュレーション用 シンプルプラン もらえるオプション
  @computed
  get simplePlansWantCarPrice(): number {
    const selectedGrade = this.nowGrades.find((item) => (item.id === this.simplePlanSelect?.gradeId));
    return getFreeTransferDiffLeasingFeeTaxedPrice(selectedGrade, this.simplePlanSelect?.term);
  }

  // PC版料金シミュレーション用 おすすめプラン もらえるオプション
  @computed
  get recommendPlansWantCarPrice(): number {
    const selectedGrade = this.nowGrades.find((item) => (item.id === this.recommendPlanSelect?.gradeId));
    return getFreeTransferDiffLeasingFeeTaxedPrice(selectedGrade, this.recommendPlanSelect?.term);
  }

  // PC版料金シミュレーション用 よくばりプラン もらえるオプション
  @computed
  get richPlansWantCarPrice(): number {
    const selectedGrade = this.nowGrades.find((item) => (item.id === this.richPlanSelect?.gradeId));
    return getFreeTransferDiffLeasingFeeTaxedPrice(selectedGrade, this.richPlanSelect?.term);
  }

  @computed
  get maintenancePlanList(): Array<IMaintenancePlan & { isChecked?: boolean }> {
    const result: Array<IMaintenancePlan & { isChecked?: boolean }> = [];
    if (this.nowCar === undefined || this.nowMaintenanceType === NEWCAR_CONST.MAINTENANCE.TYPE.NOT_APPLICABLE) { return result; }

    // 期間に合わせて変動
    const term = (this.nowCar && this.nowCar.term) ? this.nowCar.term : NEWCAR_CONST.TERM.ID.YEAR_11;

    const plans = NEWCAR_DEFINE.MAINTENANCE.PLANS(this.nowMaintenanceType, term);

    if (term > NEWCAR_CONST.TERM.ID.YEAR_5) {
      result.push({
        ...plans[NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM],
        isChecked: (this.nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM),
      });
    }
    result.push({
      ...plans[NEWCAR_CONST.MAINTENANCE.PLAN.GOLD],
      isChecked: (this.nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD),
    });
    result.push({
      ...plans[NEWCAR_CONST.MAINTENANCE.PLAN.SILVER],
      isChecked: (this.nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER),
    });
    result.push({
      ...plans[NEWCAR_CONST.MAINTENANCE.PLAN.NONE],
      isChecked: (this.nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.NONE),
    });
    return result;
  }

  @computed
  get nowPickedMaintenancePlan(): number {
    if (this.nowCar && this.nowCar.maintenancePlan) {
      return this.nowCar.maintenancePlan;
    }
    return NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED;
  }

  @computed
  get nowGrade(): VehicleGrade | undefined {
    let targetGrade: VehicleGrade | undefined;
    if (this.nowCar && this.nowCar.gradeId) {
      const targetGradeId = this.nowCar.gradeId;
      targetGrade = (this.nowGrades.find((grade) => (grade.id == targetGradeId)));
    }
    return targetGrade;
  }

  @computed
  get nowCarDigest(): CarDigest | undefined {
    const car: CarDigest | undefined = searchCarStore.allCarDigests
        && searchCarStore.allCarDigests.find((c) => c.id__normalized.toString() === this.lastEntryId);
    return car;
  }

  @computed
  get nowCustomerSelection(): CustomerSelection | undefined {
    const nowCar = new CustomerSelection();
    if (this.nowCar) {
      nowCar.id = this.nowCar.id;
      nowCar.term = this.nowCar.term;
      nowCar.engine = this.nowCar.engine;
      nowCar.gradeName = this.nowCar.gradeName;
      nowCar.kudou = this.nowCar.kudou;
      nowCar.mission = this.nowCar.mission;
      nowCar.gradeId = this.nowCar.gradeId;
      nowCar.bodyColorId = this.nowCar.bodyColorId;
      nowCar.carNavi = this.nowCar.carNavi;
      nowCar.etc = this.nowCar.etc;
      nowCar.backCamera = this.nowCar.backCamera;
      nowCar.floorMat = this.nowCar.floorMat;
      nowCar.doorVisor = this.nowCar.doorVisor;
      nowCar.dontHaveDoorVisor = this.nowCar.dontHaveDoorVisor;
      nowCar.wantNo = this.nowCar.wantNo;
      nowCar.wantCar = this.nowCar.wantCar;
      nowCar.maintenancePlan = this.nowCar.maintenancePlan;
      nowCar.masterNumber = this.nowCar.masterNumber;
    }
    return nowCar;
  }

  /**
   * Action:現在画面上で表示する車種を設定
   * @param {string} id
   * @returns
   * @memberof SimulationManager
   */
  @action
  changeId(id: string) {
    this.setId(id);
    return id;
  }

  /**
   * mutation:現在車種変更
   * @param {string} id
   * @memberof SimulationManager
   */
  @action
  setId(id: string) {
    this.lastEntryId = id;
    // 既存のシミュレーションか確認
    const hasOld = (this.userSelect.find((car) => (car.id == id)));
    // 現在車種配下のグレードを抜き出し
    if (searchCarStore.allCarDigests != undefined) {
      const resultCarDigest = (searchCarStore.allCarDigests.find((digest) => (digest.id__normalized == id)));
      if (resultCarDigest != undefined && resultCarDigest.maintenanceType != undefined) {
        this.nowMaintenanceType = resultCarDigest.maintenanceType;
      }
    }
    if (searchCarStore.allCarDetails != undefined) {
      const resultCar = (searchCarStore.allCarDetails.find((car) => (car.id == id)));
      if (resultCar != undefined && resultCar.vehicleGrades != undefined) {
        this.nowGrades = resultCar.vehicleGrades;
      } else {
        this.nowGrades = [];
      }
    }
    // 既存の場合過去値をロード
    if (hasOld) {
      this.nowCar = hasOld;
    } else {
      this.nowCar = undefined;
    }

    // PC版料金シミュレーション用、オリジナルプラン以外は前回値を反映させない
    if (this.nowGrades.length > 0) {
      this.simplePlanSelect = createNewCarCollection(id, NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE, this.nowGrades, this.nowMaintenanceType);
      this.recommendPlanSelect = createNewCarCollection(id, NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND, this.nowGrades, this.nowMaintenanceType);
      this.recommendSPPlanSelect = createNewCarCollection(id, NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP, this.nowGrades, this.nowMaintenanceType);
      const car: CarDigest | undefined = searchCarStore.allCarDigests && searchCarStore.allCarDigests.find((c) => c.id__normalized.toString() === this.lastEntryId);
      this.richPlanSelect = createNewCarCollection(id, NEWCAR_CONST.SIMULATION_PLAN.ID.RICH, this.nowGrades, this.nowMaintenanceType, (car && car.hasWantCar) ? car.hasWantCar : false);
    }
  }

  // シミュレーション開始
  @action
  startSimulation(id: string) {
    this.setStart(id);
    return id;
  }

  @action
  setStart(id: string) {
    // 既存のシミュレーションか確認
    const hasOld = (this.userSelect.find((car) => (car.id == id)));
    // 初回の場合、デフォルトを設定
    if (!hasOld || (this.nowCar && !this.nowCar.gradeId)) {
      const newCar = createNewCarCollection(id, NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL, this.nowGrades, this.nowMaintenanceType);

      this.nowCar = newCar;
      this.userSelect.push(newCar);
    }
  }

  // 期間

  @action
  changeTerm(termId: NEWCAR_CONST.TERM.ID) {
    this.setTerm(termId);
    return termId;
  }

  @action
  setTerm(termId: NEWCAR_CONST.TERM.ID) {
    if (this.nowCar) {
      if (this.nowCar.term && this.nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) {
        // 更新前の選択期間が6年以上のとき かつ 新規選択期間が5年以下のとき
        if (this.nowCar.term >= NEWCAR_CONST.TERM.ID.YEAR_6 && termId <= NEWCAR_CONST.TERM.ID.YEAR_5) {
          // すでに選択ずみのメンテプランをリセットする
          this.nowCar = { ...this.nowCar, maintenancePlan: NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED };
          this.isResetMaintenance = true;
        }
        // 更新前の選択期間が5年以下のとき かつ 新規選択期間が6年以上のとき
        if (this.nowCar.term! <= NEWCAR_CONST.TERM.ID.YEAR_5 && termId >= NEWCAR_CONST.TERM.ID.YEAR_6) {
          // すでに選択ずみのメンテプランをリセットする
          this.nowCar = { ...this.nowCar, maintenancePlan: NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED };
          this.isResetMaintenance = true;
        }
      }
      this.nowCar = { ...this.nowCar, term: termId };
      Log.trace(`new Termr ID: ${termId}`);
    }
  }

  @action
  incrementTerm() {
    this.incrementTermData();
  }

  @action
  incrementTermData() {
    if (this.nowCar) {
      let term = 11;
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_11) { term = NEWCAR_CONST.TERM.ID.YEAR_11; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_10) { term = NEWCAR_CONST.TERM.ID.YEAR_11; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_9) { term = NEWCAR_CONST.TERM.ID.YEAR_10; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_8) { term = NEWCAR_CONST.TERM.ID.YEAR_9; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_7) { term = NEWCAR_CONST.TERM.ID.YEAR_8; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_6) { term = NEWCAR_CONST.TERM.ID.YEAR_7; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_5) {
        if (this.nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) {
          this.isResetMaintenance = true;
          this.nowCar = { ...this.nowCar, maintenancePlan: NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED };
          this.setIsShowSimulateAlert(true);
        }
        term = NEWCAR_CONST.TERM.ID.YEAR_6;
      }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_4) { term = NEWCAR_CONST.TERM.ID.YEAR_5; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_3) { term = NEWCAR_CONST.TERM.ID.YEAR_4; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_2) { term = NEWCAR_CONST.TERM.ID.YEAR_3; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_1) { term = NEWCAR_CONST.TERM.ID.YEAR_2; }
      Log.trace(`new Termr ID: ${this.nowCar.term}`);
      this.nowCar = { ...this.nowCar, term };
    }
  }

  @action
  decrementTerm() {
    this.decrementTermData();
    return true;
  }

  @action
  decrementTermData() {
    if (this.nowCar) {
      let term = 11;
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_1) { term = NEWCAR_CONST.TERM.ID.YEAR_1; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_2) { term = NEWCAR_CONST.TERM.ID.YEAR_1; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_3) { term = NEWCAR_CONST.TERM.ID.YEAR_2; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_4) { term = NEWCAR_CONST.TERM.ID.YEAR_3; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_5) { term = NEWCAR_CONST.TERM.ID.YEAR_4; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_6) {
        if (this.nowCar.maintenancePlan === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM) {
          this.isResetMaintenance = true;
          this.nowCar = { ...this.nowCar, maintenancePlan: NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED };
          this.setIsShowSimulateAlert(true);
        }
        term = NEWCAR_CONST.TERM.ID.YEAR_5;
      }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_7) { term = NEWCAR_CONST.TERM.ID.YEAR_6; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_8) { term = NEWCAR_CONST.TERM.ID.YEAR_7; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_9) { term = NEWCAR_CONST.TERM.ID.YEAR_8; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_10) { term = NEWCAR_CONST.TERM.ID.YEAR_9; }
      if (this.nowCar.term === NEWCAR_CONST.TERM.ID.YEAR_11) { term = NEWCAR_CONST.TERM.ID.YEAR_10; }
      Log.trace(`new Termr ID: ${this.nowCar.term}`);
      this.nowCar = { ...this.nowCar, term };
    }
  }

  // グレード
  @action
  setGrade(grade: VehicleGrade) {
    if (!this.lastEntryId || !this.nowCar) {
      return;
    }

    // グレードより後の項目を初期化するために初期値を取得
    const newNowCar = createNewCarCollection(this.lastEntryId, NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL, this.nowGrades, this.nowMaintenanceType, undefined, grade);

    // グレードより前の項目はそのまま引き継ぐ
    newNowCar.term = this.nowCar.term;
    newNowCar.engine = this.nowCar.engine;
    newNowCar.kudou = this.nowCar.kudou;
    newNowCar.mission = this.nowCar.mission;

    newNowCar.gradeId = grade.id;
    newNowCar.gradeName = grade.name;

    this.nowCar = newNowCar;
  }

  // エンジン
  @action
  setEngine(engine: CustomerSelection['engine']) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, engine };
    }
    let grades = this.nowGrades;
    // エンジンタイプで絞り込み
    grades = grades.filter((grade) => (grade.engine || 'ガソリン') === NEWCAR_CONST.ENGINE.NAME[engine!]);
    // 4WDのみの場合は4WDを選択
    const kudouList = grades.map((grade) => grade.kudou || '');
    if (!kudouList.includes('2WD') && kudouList.includes('4WD')) {
      this.setKudou(4);
    }
    // ATのみの場合はATを選択
    const missionTypeList = grades.map((grade) => getMissionType(grade.mission || ''));
    if (!missionTypeList.includes('AT') && missionTypeList.includes('MT')) {
      this.setMission('MT');
    }
    // グレードを初期化
    const newGrade = getDefaultGrade(this.nowGrades, this.nowCar);
    if (newGrade) {
      this.setGrade(newGrade);
    }
  }

  // 駆動
  @action
  setKudou(kudou: CustomerSelection['kudou']) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, kudou };
    }
    // グレードを初期化
    const newGrade = getDefaultGrade(this.nowGrades, this.nowCar);
    if (newGrade) {
      this.setGrade(newGrade);
    }
  }

  // ミッション
  @action
  setMission(mission: CustomerSelection['mission']) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, mission };
    }
    // グレードを初期化
    const newGrade = getDefaultGrade(this.nowGrades, this.nowCar);
    if (newGrade) {
      this.setGrade(newGrade);
    }
  }

  // Action:ボディカラー選択
  @action
  changeBodyColor(bodyColorId: string) {
    this.setBodyColor(bodyColorId);
    return bodyColorId;
  }

  @action
  setBodyColor(bodyColorId: string) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, bodyColorId };
      Log.trace(`new Body Color ID: ${bodyColorId}`);
      this.isResetBodyColor = false;
    }
  }

  // PC版料金シミュレーション用 シンプルプラン ボディカラー選択
  @action
  changeSimpleBodyColor(bodyColorId: string) {
    this.setSimpleBodyColor(bodyColorId);
    return bodyColorId;
  }

  @action
  setSimpleBodyColor(bodyColorId: string) {
    if (this.simplePlanSelect) {
      this.simplePlanSelect = { ...this.simplePlanSelect, bodyColorId };
      Log.trace(`new Simple Body Color ID: ${bodyColorId}`);
    }
  }

  // PC版料金シミュレーション用 おすすめプラン ボディカラー選択
  @action
  changeRecommendBodyColor(bodyColorId: string) {
    this.setRecommendBodyColor(bodyColorId);
    return bodyColorId;
  }

  @action
  setRecommendBodyColor(bodyColorId: string) {
    if (this.recommendPlanSelect) {
      this.recommendPlanSelect = { ...this.recommendPlanSelect, bodyColorId };
      Log.trace(`new Recommend Body Color ID: ${bodyColorId}`);
    }
  }

  // PC版料金シミュレーション用 よくばりプラン ボディカラー選択
  @action
  changeRichBodyColor(bodyColorId: string) {
    this.setRichBodyColor(bodyColorId);
    return bodyColorId;
  }

  @action
  setRichBodyColor(bodyColorId: string) {
    if (this.richPlanSelect) {
      this.richPlanSelect = { ...this.richPlanSelect, bodyColorId };
      Log.trace(`new Simple Body Color ID: ${bodyColorId}`);
    }
  }

  // Action:カーナビ選択

  @action
  changeCarNavi(optionId: string) {
    this.setCarNavi(optionId);
    return optionId;
  }

  @action
  setCarNavi(optionId: string) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, carNavi: optionId };
      Log.trace(`new Option ID[carnavi]: ${optionId}`);
      this.isResetOption = false;
    }
  }

  @action
  setCarNaviByGradeId(gradeId : number) {
    // グレードIDでカーナビを再選択
    const nowGrade = (this.nowGrades.find((grade) => (grade.id == gradeId)));
    if (nowGrade && nowGrade.options && searchCarStore.allOptionInfoList) {
      for (const targetOption of nowGrade.options) {
        const nowOptions = (searchCarStore.allOptionInfoList.find((option) => (option.id == targetOption.optionInfoId)));
        if (nowOptions && nowOptions.id && nowOptions.optionType === 'カーナビ') {
          this.changeCarNavi(nowOptions.id.toString());
        }
      }
    }
  }

  // PC版料金シミュレーション用 シンプルプラン Action:カーナビ選択

  @action
  changeSimpleCarNavi(optionId: string) {
    this.setSimpleCarNavi(optionId);
    return optionId;
  }

  @action
  setSimpleCarNavi(optionId: string) {
    if (this.simplePlanSelect) {
      this.simplePlanSelect = { ...this.simplePlanSelect, carNavi: optionId };
      Log.trace(`new Simple Option ID[carnavi]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 おすすめプラン Action:カーナビ選択

  @action
  changeRecommendCarNavi(optionId: string) {
    this.setRecommendCarNavi(optionId);
    return optionId;
  }

  @action
  setRecommendCarNavi(optionId: string) {
    if (this.recommendPlanSelect) {
      this.recommendPlanSelect = { ...this.recommendPlanSelect, carNavi: optionId };
      Log.trace(`new Recommend Option ID[carnavi]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 よくばりプラン Action:カーナビ選択

  @action
  changeRichCarNavi(optionId: string) {
    this.setRichCarNavi(optionId);
    return optionId;
  }

  @action
  setRichCarNavi(optionId: string) {
    if (this.richPlanSelect) {
      this.richPlanSelect = { ...this.richPlanSelect, carNavi: optionId };
      Log.trace(`new Rich Option ID[carnavi]: ${optionId}`);
    }
  }

  // Action:ETC選択

  @action
  changeETC(optionId: string) {
    this.setETC(optionId);
    return optionId;
  }

  @action
  setETC(optionId: string) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, etc: optionId };
      Log.trace(`new Option ID[etc]: ${optionId}`);
      this.isResetOption = false;
    }
  }

  // PC版料金シミュレーション用 シンプルプラン Action:ETC選択

  @action
  changeSimpleETC(optionId: string) {
    this.setSimpleETC(optionId);
    return optionId;
  }

  @action
  setSimpleETC(optionId: string) {
    if (this.simplePlanSelect) {
      this.simplePlanSelect = { ...this.simplePlanSelect, etc: optionId };
      Log.trace(`new Simple Option ID[etc]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 おすすめプラン Action:ETC選択

  @action
  changeRecommendETC(optionId: string) {
    this.setRecommendETC(optionId);
    return optionId;
  }

  @action
  setRecommendETC(optionId: string) {
    if (this.recommendPlanSelect) {
      this.recommendPlanSelect = { ...this.recommendPlanSelect, etc: optionId };
      Log.trace(`new Recommend Option ID[etc]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 よくばりプラン Action:ETC選択

  @action
  changeRichETC(optionId: string) {
    this.setRichETC(optionId);
    return optionId;
  }

  @action
  setRichETC(optionId: string) {
    if (this.richPlanSelect) {
      this.richPlanSelect = { ...this.richPlanSelect, etc: optionId };
      Log.trace(`new Rich Option ID[etc]: ${optionId}`);
    }
  }

  // Action:バックカメラ選択

  @action
  changeBackCamera(optionId: string) {
    this.setBackCamera(optionId);
    return optionId;
  }

  @action
  setBackCamera(optionId: string) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, backCamera: optionId };
      Log.trace(`new Option ID[backCamera]: ${optionId}`);
      this.isResetOption = false;
    }
  }

  // PC版料金シミュレーション用 シンプルプラン Action:バックカメラ選択

  @action
  changeSimpleBackCamera(optionId: string) {
    this.setSimpleBackCamera(optionId);
    return optionId;
  }

  @action
  setSimpleBackCamera(optionId: string) {
    if (this.simplePlanSelect) {
      this.simplePlanSelect = { ...this.simplePlanSelect, backCamera: optionId };
      Log.trace(`new Simple Option ID[backCamera]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 おすすめプラン Action:バックカメラ選択

  @action
  changeRecommendBackCamera(optionId: string) {
    this.setRecommendBackCamera(optionId);
    return optionId;
  }

  @action
  setRecommendBackCamera(optionId: string) {
    if (this.recommendPlanSelect) {
      this.recommendPlanSelect = { ...this.recommendPlanSelect, backCamera: optionId };
      Log.trace(`new Recommend Option ID[backCamera]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 よくばりプラン Action:バックカメラ選択

  @action
  changeRichBackCamera(optionId: string) {
    this.setRichBackCamera(optionId);
    return optionId;
  }

  @action
  setRichBackCamera(optionId: string) {
    if (this.richPlanSelect) {
      this.richPlanSelect = { ...this.richPlanSelect, backCamera: optionId };
      Log.trace(`new Rich Option ID[backCamera]: ${optionId}`);
    }
  }

  // Action:フロアマット選択

  @action
  changeFloorMat(optionId: string) {
    this.setFloorMat(optionId);
    return optionId;
  }

  @action
  setFloorMat(optionId: string) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, floorMat: optionId };
      Log.trace(`new Option ID[floorMat]: ${optionId}`);
      this.isResetOption = false;
    }
  }

  // PC版料金シミュレーション用 シンプルプラン Action:フロアマット選択

  @action
  changeSimpleFloorMat(optionId: string) {
    this.setSimpleFloorMat(optionId);
    return optionId;
  }

  @action
  setSimpleFloorMat(optionId: string) {
    if (this.simplePlanSelect) {
      this.simplePlanSelect = { ...this.simplePlanSelect, floorMat: optionId };
      Log.trace(`new Simple Option ID[floorMat]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 おすすめプラン Action:フロアマット選択

  @action
  changeRecommendFloorMat(optionId: string) {
    this.setRecommendFloorMat(optionId);
    return optionId;
  }

  @action
  setRecommendFloorMat(optionId: string) {
    if (this.recommendPlanSelect) {
      this.recommendPlanSelect = { ...this.recommendPlanSelect, floorMat: optionId };
      Log.trace(`new Recommend Option ID[floorMat]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 よくばりプラン Action:フロアマット選択

  @action
  changeRichFloorMat(optionId: string) {
    this.setRichFloorMat(optionId);
    return optionId;
  }

  @action
  setRichFloorMat(optionId: string) {
    if (this.richPlanSelect) {
      this.richPlanSelect = { ...this.richPlanSelect, floorMat: optionId };
      Log.trace(`new Rich Option ID[floorMat]: ${optionId}`);
    }
  }

  // Action:ドアバイザー選択

  @action
  changeDoorVisor(optionId: string) {
    this.setDoorVisor(optionId);
    return optionId;
  }

  @action
  setDoorVisor(optionId: string) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, doorVisor: optionId };
      Log.trace(`new Option ID[doorVisor]: ${optionId}`);
      this.isResetOption = false;
    }
  }

  // PC版料金シミュレーション用 シンプルプラン Action:ドアバイザー選択

  @action
  changeSimpleDoorVisor(optionId: string) {
    this.setSimpleDoorVisor(optionId);
    return optionId;
  }

  @action
  setSimpleDoorVisor(optionId: string) {
    if (this.simplePlanSelect) {
      this.simplePlanSelect = { ...this.simplePlanSelect, doorVisor: optionId };
      Log.trace(`new Simple Option ID[doorVisor]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 おすすめプラン Action:ドアバイザー選択

  @action
  changeRecommendDoorVisor(optionId: string) {
    this.setRecommendDoorVisor(optionId);
    return optionId;
  }

  @action
  setRecommendDoorVisor(optionId: string) {
    if (this.recommendPlanSelect) {
      this.recommendPlanSelect = { ...this.recommendPlanSelect, doorVisor: optionId };
      Log.trace(`new Recommend Option ID[doorVisor]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 よくばりプラン Action:ドアバイザー選択

  @action
  changeRichDoorVisor(optionId: string) {
    this.setRichDoorVisor(optionId);
    return optionId;
  }

  @action
  setRichDoorVisor(optionId: string) {
    if (this.richPlanSelect) {
      this.richPlanSelect = { ...this.richPlanSelect, doorVisor: optionId };
      Log.trace(`new Rich Option ID[doorVisor]: ${optionId}`);
    }
  }

  // Action:希望ナンバー

  @action
  changeWantNo(optionId: string) {
    this.setWantNo(optionId);
    return optionId;
  }

  @action
  setWantNo(optionId: string) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, wantNo: optionId };
      Log.trace(`new Option ID[wantNo]: ${optionId}`);
      this.isResetOption = false;
    }
  }

  // PC版料金シミュレーション用 シンプルプラン Action:希望ナンバー

  @action
  changeSimpleWantNo(optionId: string) {
    this.setSimpleWantNo(optionId);
    return optionId;
  }

  @action
  setSimpleWantNo(optionId: string) {
    if (this.simplePlanSelect) {
      this.simplePlanSelect = { ...this.simplePlanSelect, wantNo: optionId };
      Log.trace(`new Simple Option ID[wantNo]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 おすすめプラン Action:希望ナンバー

  @action
  changeRecommendWantNo(optionId: string) {
    this.setRecommendWantNo(optionId);
    return optionId;
  }

  @action
  setRecommendWantNo(optionId: string) {
    if (this.recommendPlanSelect) {
      this.recommendPlanSelect = { ...this.recommendPlanSelect, wantNo: optionId };
      Log.trace(`new Recommend Option ID[wantNo]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 よくばりプラン Action:希望ナンバー

  @action
  changeRichWantNo(optionId: string) {
    this.setRichWantNo(optionId);
    return optionId;
  }

  @action
  setRichWantNo(optionId: string) {
    if (this.richPlanSelect) {
      this.richPlanSelect = { ...this.richPlanSelect, wantNo: optionId };
      Log.trace(`new Rich Option ID[wantNo]: ${optionId}`);
    }
  }

  // Action:もらえるオプション

  @action
  changeWantCar(optionId: string) {
    this.setWantCar(optionId);
    return optionId;
  }

  @action
  setWantCar(optionId: string) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, wantCar: optionId };
      Log.trace(`new Option ID[wantCar]: ${optionId}`);
      this.isResetOption = false;
    }
  }

  // PC版料金シミュレーション用 シンプルプラン Action:もらえるオプション

  @action
  changeSimpleWantCar(optionId: string) {
    this.setSimpleWantCar(optionId);
    return optionId;
  }

  @action
  setSimpleWantCar(optionId: string) {
    if (this.simplePlanSelect) {
      this.simplePlanSelect = { ...this.simplePlanSelect, wantCar: optionId };
      Log.trace(`new Simple Option ID[wantCar]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 おすすめプラン Action:もらえるオプション

  @action
  changeRecommendWantCar(optionId: string) {
    this.setRecommendWantCar(optionId);
    return optionId;
  }

  @action
  setRecommendWantCar(optionId: string) {
    if (this.recommendPlanSelect) {
      this.recommendPlanSelect = { ...this.recommendPlanSelect, wantCar: optionId };
      Log.trace(`new Recommend Option ID[wantCar]: ${optionId}`);
    }
  }

  // PC版料金シミュレーション用 よくばりプラン Action:もらえるオプション

  @action
  changeRichWantCar(optionId: string) {
    this.setRichWantCar(optionId);
    return optionId;
  }

  @action
  setRichWantCar(optionId: string) {
    if (this.richPlanSelect) {
      this.richPlanSelect = { ...this.richPlanSelect, wantCar: optionId };
      Log.trace(`new Rich Option ID[wantCar]: ${optionId}`);
    }
  }

  // Action:メンテパック

  @action
  changeMaintenance(plan: NEWCAR_CONST.MAINTENANCE.PLAN) {
    this.setMaintenance(plan);
    return plan;
  }

  @action
  setMaintenance(plan: NEWCAR_CONST.MAINTENANCE.PLAN) {
    if (this.nowCar) {
      this.nowCar = { ...this.nowCar, maintenancePlan: plan };
      this.isResetMaintenance = false;
      Log.trace(`new Maintenance Plan ID: ${plan}`);
    }
  }

  // Action:条件をクリア

  @action
  clearSelection() {
    this.clearSelectionData();
    return true;
  }

  @action
  clearSelectionData() {
    // 対象車種を格納
    if (this.nowCar) {
      // // デフォルトを設定
      //            this.nowCar.engine = undefined;
      const clearCar = {
        ...this.nowCar,
        gradeName: undefined,
        kudou: undefined,
        capacity: undefined,
        mission: undefined,
        removedSafetySystem: undefined,
        gradeId: undefined,
        bodyColorId: undefined,
        carNavi: undefined,
        etc: undefined,
        backCamera: undefined,
        floorMat: undefined,
        doorVisor: undefined,
        wantNo: undefined,
        maintenancePlan: NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED,
        masterNumber: searchCarStore.masterNumber,
      };
      this.nowCar = clearCar;
      this.wantCar = undefined;
      this.isResetBodyColor = false;
      this.isResetOption = false;
      this.isResetMaintenance = false;
    }
  }

  // 保存済みの料金シミュレーションをロード
  @action
  changeUserSelect(newSelection : CustomerSelection) {
    this.setUserSelect(newSelection);
    return newSelection;
  }

  @action
  setUserSelect(newSelection : CustomerSelection) {
    if (!newSelection.id) { return; }

    const nowUserSelect = (this.userSelect.find((selection) => newSelection.id == selection.id));

    if (nowUserSelect) {
      nowUserSelect.backCamera = newSelection.backCamera;
      nowUserSelect.bodyColorId = newSelection.bodyColorId;
      nowUserSelect.carNavi = newSelection.carNavi;
      nowUserSelect.dontHaveDoorVisor = newSelection.dontHaveDoorVisor;
      nowUserSelect.doorVisor = newSelection.doorVisor;
      nowUserSelect.engine = newSelection.engine;
      nowUserSelect.etc = newSelection.etc;
      nowUserSelect.floorMat = newSelection.floorMat;
      nowUserSelect.gradeId = newSelection.gradeId;
      nowUserSelect.gradeName = newSelection.gradeName;
      nowUserSelect.kudou = newSelection.kudou;
      nowUserSelect.maintenancePlan = newSelection.maintenancePlan;
      nowUserSelect.masterNumber = newSelection.masterNumber;
      nowUserSelect.mission = newSelection.mission;
      nowUserSelect.term = newSelection.term;
      nowUserSelect.wantCar = newSelection.wantCar;
      nowUserSelect.wantNo = newSelection.wantNo;
    } else {
      this.userSelect.push(newSelection);
    }
  }

  @computed
  get isOldSimulation():boolean {
    return !!((this.nowCar && this.nowCar.masterNumber && (this.nowCar.masterNumber != searchCarStore.masterNumber)));
  }

  // パラメータ部分を返却する
  @computed
  get urlParams() : string {
    let params = '';
    if (this.nowCar) {
      const selection = this.nowCar;
      let count = 1;
      params += 't=';
      params += (selection.term) ? selection.term : NEWCAR_CONST.TERM.ID.YEAR_11; // 期間
      if (selection.gradeId) { params += `&g=${selection.gradeId}`; count += 1; } // グレードID
      if (selection.bodyColorId) { params += `&b=${selection.bodyColorId}`; count += 1; } // ボディカラー
      if (selection.carNavi && selection.carNavi != '100001') { params += `&c=${selection.carNavi}`; count += 1; } // カーナビ
      if (selection.etc && selection.etc != '100003') { params += `&e=${selection.etc}`; count += 1; } // ETC
      if (selection.backCamera && selection.backCamera != '100002') { params += `&b2=${selection.backCamera}`; count += 1; } // バックカメラ
      if (selection.floorMat && selection.floorMat != '100004') { params += `&f=${selection.floorMat}`; count += 1; } // フロアマット
      if (selection.doorVisor && selection.doorVisor != '100006') { params += `&d=${selection.doorVisor}`; count += 1; } // ドアバイザー
      if (selection.dontHaveDoorVisor) { params += `&d2=${1}`; count += 1; } // ドアバイザー無しの車両
      if (selection.wantNo && selection.wantNo != '100005') { params += `&w=${selection.wantNo}`; count += 1; } // 希望ナンバー
      if (selection.wantCar && selection.wantCar != 'no') { params += `&w2=${selection.wantCar}`; count += 1; } // もらえるオプション
      if (selection.maintenancePlan) { params += `&m=${selection.maintenancePlan}`; count += 1; } // メンテナンスプラン
      if (selection.masterNumber) { params += `&m2=${selection.masterNumber}`; count += 1; } // マスタ番号
      params += `&c2=${count}`;
    }

    return params;
  }

  @action
  changeGrade(id : string) {
    this.setGrades(id);
    return id;
  }

  @action
  setGrades(id : string) {
    if (!id) { return {}; }

    if (searchCarStore.allCarDetails != undefined) {
      const resultCar = (searchCarStore.allCarDetails.find((car) => (car.id == id)));
      if (resultCar != undefined && resultCar.vehicleGrades != undefined) {
        this.nowGrades = resultCar.vehicleGrades;
        return resultCar.vehicleGrades;
      }
    }
    return {};
  }

  // パラメータから料金シミュレーションの値を設定
  @action
  changeUserSelectFromParam(data: {id : string, params : any}) {
    if (!data.id) { return {}; }

    const newSelection = new CustomerSelection();

    newSelection.id = data.id; // 車種ID
    newSelection.term = Number((data.params.t) ? data.params.t : NEWCAR_CONST.TERM.ID.YEAR_11); // 期間

    // グレードID
    const gradeId = data.params.g;
    if (gradeId) {
      if (searchCarStore.allCarDetails != undefined) {
        const resultCar = (searchCarStore.allCarDetails.find((car) => (car.id == data.id)));

        if (resultCar != undefined && resultCar.vehicleGrades != undefined) {
          const grades = resultCar.vehicleGrades;
          // グレードIDのが変更になったので、駆動以下を該当するものに変更する
          const targetGrade = (grades.find((grade) => (grade.id == gradeId)));

          if (targetGrade) {
            // グレードID
            if (targetGrade.id) {
              newSelection.gradeId = targetGrade.id;
            }
            // グレード名称
            if (targetGrade.name) {
              newSelection.gradeName = targetGrade.name;
            }
            // 駆動
            if (targetGrade.kudou) {
              newSelection.kudou = NEWCAR_CONST.KUDOU.NAME_TO_ID(targetGrade.kudou);
            }
            // ミッション
            if (targetGrade.mission) {
              newSelection.mission = getMissionType(targetGrade.mission);
            }
            // エンジン種別
            if (targetGrade.engine) {
              newSelection.engine = NEWCAR_CONST.ENGINE.NAME_TO_ID(targetGrade.engine);
            } else {
              newSelection.engine = NEWCAR_CONST.ENGINE.ID.IS_GAS;
            }
            // ボディカラー
            newSelection.bodyColorId = data.params.b;

            // カーナビ
            if (data.params.c) {
              newSelection.carNavi = data.params.c;
            } else {
              newSelection.carNavi = '100001';
            }
            // ETC
            if (data.params.e) {
              newSelection.etc = data.params.e;
            } else {
              newSelection.etc = '100003';
            }
            // バックカメラ
            if (data.params.b2) {
              newSelection.backCamera = data.params.b2;
            } else {
              newSelection.backCamera = '100002';
            }
            // フロアマット
            if (data.params.f) {
              newSelection.floorMat = data.params.f;
            } else {
              newSelection.floorMat = '100004';
            }
            // ドアバイザー
            if (data.params.d) {
              newSelection.doorVisor = data.params.d;
            } else {
              newSelection.doorVisor = '100006';
            }
            // ドアバイザー無しの車両
            if (data.params.d2) {
              newSelection.dontHaveDoorVisor = true;
            } else {
              newSelection.dontHaveDoorVisor = false;
            }
            // 希望ナンバー
            if (data.params.w) {
              newSelection.wantNo = data.params.w;
            } else {
              newSelection.wantNo = '100005';
            }
            // もらえるオプション
            if (data.params.w2) {
              newSelection.wantCar = data.params.w2;
            } else {
              newSelection.wantCar = 'no';
            }
            // メンテナンスプラン
            if (data.params.m) {
              newSelection.maintenancePlan = Number(data.params.m);
            } else {
              newSelection.maintenancePlan = NEWCAR_CONST.MAINTENANCE.PLAN.NOT_SELECTED;
            }
            // マスタ番号
            if (data.params.m2) {
              newSelection.masterNumber = data.params.m2;
            }
          }
        }
      }
    }

    this.setUserSelect(newSelection);
    return newSelection;
  }

  @action
  nowCarToUndefined() {
    this.nowCar = undefined;
  }

  // 車両IDとキャンペーンIDから車種に関する申込情報作成
  @action
  createCampaignBreakdownFromQuery(query: any): void {
    const KINTONE_MAX_LENGTH = 128;

    const defaultTerm = NEWCAR_CONST.TERM.ID.YEAR_11;
    const term = query.term || defaultTerm;
    const from = query.from || '';
    const carCondition = query.condition || '';
    const carSelect = query.carselect || '';
    const planDetails = query.plandetails || '';
    const cta = query.cta || '';
    const price = query.price || '';

    // 即納車の場合
    const isQuickDelivery = !!~from.indexOf(NEWCAR_CONST.PLAN_PATTERN.QUERY.DELIVERY);

    let plan = '';
    if (query.plan) {
      // planパラメータがセットされている場合は優先して採用
      plan = query.plan;
    } else {
      // TODO: コンテンツ制作側での対応が完了したらこちらの処理は不要になる

      // eslint-disable-next-line no-lonely-if
      if (~from.indexOf(NEWCAR_CONST.PLAN_PATTERN.QUERY.MAG_QUICK)) {
        // マガジン流入の場合
        plan = NEWCAR_CONST.PLAN_PATTERN.NAME.MAG_QUICK;
      } else if (isQuickDelivery) { // 即納車の場合（クエリに”deli”が含まれている）で、
        // クエリに"lp"が含まれていたら
        if (~from.indexOf(NEWCAR_CONST.PLAN_PATTERN.QUERY.LP)) {
          plan = NEWCAR_CONST.PLAN_PATTERN.NAME.LP_QUICK_DELIVERY;
        } else { // そうでなければ
          plan = NEWCAR_CONST.PLAN_PATTERN.NAME.MAIN_QUICK_DELIVERY;
        }
      } else if (~from.indexOf(NEWCAR_CONST.PLAN_PATTERN.QUERY.LP)) { // ”deli”は含まれてないけど、"lp"がクエリに含まれていれば
        plan = NEWCAR_CONST.PLAN_PATTERN.NAME.LP_QUICK;
      } else if (~from.indexOf(NEWCAR_CONST.PLAN_PATTERN.QUERY.MAIN_LP)) { // ”deli”は含まれてないけど、"mainLp"がクエリに含まれていれば
        plan = NEWCAR_CONST.PLAN_PATTERN.NAME.MAIN_LP;
      } else if (~from.indexOf(NEWCAR_CONST.PLAN_PATTERN.QUERY.OTAMESHI)) {
        plan = NEWCAR_CONST.PLAN_PATTERN.NAME.OTAMESHI;
      } else if (~from.indexOf(NEWCAR_CONST.PLAN_PATTERN.QUERY.CONSULT)) {
        plan = NEWCAR_CONST.PLAN_PATTERN.NAME.CONSULT;
      } else if (~from.indexOf(NEWCAR_CONST.PLAN_PATTERN.QUERY.TRIAL)) {
        plan = NEWCAR_CONST.PLAN_PATTERN.NAME.TRIAL;
      }
    }

    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'referrerPlan', payload: plan.substring(0, KINTONE_MAX_LENGTH) });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'referrerLpMonthlyPrice', payload: price.substring(0, KINTONE_MAX_LENGTH) });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'referrerLpLink', payload: from.substring(0, KINTONE_MAX_LENGTH) });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'referrerType', payload: from.substring(0, KINTONE_MAX_LENGTH) });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'carCondition', payload: carCondition.substring(0, KINTONE_MAX_LENGTH) });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'carSelectStatus', payload: carSelect.substring(0, KINTONE_MAX_LENGTH) });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'planDetails', payload: planDetails.substring(0, KINTONE_MAX_LENGTH) });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'cta', payload: cta.substring(0, KINTONE_MAX_LENGTH) });

    // optionTypesからfieldを取得する用途のオブジェクト
    interface OptionTypes {
      カーナビ: string,
      バックカメラ: string,
      ETC: string,
      フロアマット: string,
      希望ナンバー: string,
      ドアバイザー: string,
    }
    const optionTypes: OptionTypes = {
      カーナビ: 'carNavi',
      バックカメラ: 'backCamera',
      ETC: 'etc',
      フロアマット: 'floorMat',
      希望ナンバー: 'wantNo',
      ドアバイザー: 'doorVisor',
    };

    // メモ設定（ユーザー表示月額は一番下のグレードでオプションなしで計算）
    let memo = plan ? `|プラン：${plan}` : '';

    // 車両情報変更
    const carId = query.carid || query.carId;
    const car: CarDigest | undefined = searchCarStore.allCarDigests
            && searchCarStore.allCarDigests.find((c) => c.id__normalized.toString() === carId);
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'car', payload: car });

    const resultCar = searchCarStore.allCarDetails
            && searchCarStore.allCarDetails.find((c) => c.id.toString() === carId);
    if (resultCar !== undefined && resultCar.vehicleGrades !== undefined) {
      const newCar = createNewCarCollection(resultCar.id, NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE, resultCar.vehicleGrades, NEWCAR_CONST.MAINTENANCE.TYPE.STANDARD);

      // グレードを設定
      const carGrades = resultCar && resultCar.vehicleGrades;

      // ミライースの場合、「2WD 5ドア L SA III 4人 660cc ガソリン DCVT」のグレードを利用する
      let gradeId = newCar.gradeId;
      if (carId === '10017') {
        const miraesGrade = resultCar.vehicleGrades.find((vehicleGrade) => vehicleGrade.id?.toString() === '71081');
        if (miraesGrade) {
          gradeId = miraesGrade.id;
        }
      }
      const carGrade = carGrades.find((grade) => grade.id === gradeId);

      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'grade', payload: carGrade });

      // オプションの設定
      if (carGrade !== undefined && carGrade.options !== undefined) {
        // optionsをforで取り回してインデックスではなくoptionTypeでオプションを特定する
        for (const option of carGrade.options) {
          // optionTypeを取得するためにoptionInfoIdからoptionInfoを紐づける
          const optionInfos: OptionInfo[] = searchCarStore.allOptionInfoList || [];
          const optionInfo = optionInfos.find((info) => info.id === option.optionInfoId);
          if (!optionInfo) {
            throw new Error('No data related to optionInfo in detail.json');
          }
          if (optionInfo.optionType === undefined) {
            throw new Error('No data related to optionType in detail.json');
          }
          if (option.isStandard) {
            const optionFees: OptionFee[] = searchCarStore.allOptionFeeList || [];
            const optionFee = optionFees.find((fee) => fee.id === option.optionFeeId) as { [key: string]: number };
            if (!optionFee) {
              throw new Error('No data related to optionFee in detail.json');
            }
            entryBreakdownManagerStore.updateEntryBreakdown({
              field: optionTypes[optionInfo.optionType as keyof typeof optionTypes],
              payload: {
                id: option.id,
                name: optionInfo.name,
                isStandard: option.isStandard,
                leasingFee: optionFee[`leasingFee${term * 12}`],
                supportFee: optionFee[`supportFee${term * 12}`],
              },
            });
          } else {
            entryBreakdownManagerStore.updateEntryBreakdown({ field: optionTypes[optionInfo.optionType as keyof typeof optionTypes], payload: undefined });
            memo += `|${optionInfo.optionType}：未選択`;
          }
        }
      }

      // ボディカラーの設定
      if (carGrade && carGrade.bodyColors) {
        const bodyColorList = selectedGradeBodyColorList(carGrade);
        if (bodyColorList.length === 0) {
          throw new Error('No body color was found for the specified grade.');
        }
        const bodyColor = bodyColorList[0];
        entryBreakdownManagerStore.updateEntryBreakdown({
          field: 'bodyColor',
          payload: {
            id: bodyColor.id,
            name: bodyColor && bodyColor.bodyColorName,
            leasingFee: bodyColor && bodyColor.leasingFee,
            supportFee: bodyColor && bodyColor.supportFee,
          },
        });
      }

      // もらえるオプション
      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'wantCar', payload: { price: getFreeTransferDiffLeasingFeeTaxedPrice(carGrade, term) } });
      memo += '|もらえるオプション：希望';
    }

    // 賃貸期間を設定
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'term', payload: term });

    // メンテナンスプランを設定
    if (car && query.m) {
      entryBreakdownManagerStore.updateEntryBreakdown({
        field: 'maintenancePlan',
        payload:
          NEWCAR_DEFINE.MAINTENANCE.PLANS(
            car.maintenanceType,
            term,
          )[query.m],
      });
      memo += `|メンテ：${NEWCAR_CONST.MAINTENANCE.PLAN_SHORT_NAME(Number(query.m))}`;
    } else {
      entryBreakdownManagerStore.updateEntryBreakdown({ field: 'maintenancePlan', payload: undefined });
      memo += '|メンテ：未選択';
    }

    memo += '|グレード：自動割当';
    memo += '|ボディカラー：未選択';
    memo += `|賃貸期間：${query.term ? `${query.term}年` : '未設定'}`;
    memo += `|流入経路：${from}|クイック審査：${car && car.name}|ユーザー表示月額：${query.price}|車種：選択済`;

    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'memo', payload: memo });
    entryBreakdownManagerStore.saveToSessionStorage();
  }
}

export const simulationManagerStore = new SimulationManagerStore();
